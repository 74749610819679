import { JsonValueProvider, getValueFromFunction, isTrue, sleep } from '@app/utils'
import { useCallback, useEffect, useState } from 'react'
import { FieldEditorProps } from '../EditorService'
import anylogger from '@app/anylogger'

const log = anylogger('useTextFieldEditor')

export function useFieldEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const { fieldDef: field, data, dataChanged } = props
	const { name, provider, editor } = field

	const { validate, sx, onChange } = editor
	const [isError, setIsError] = useState<boolean>(false)
	const [errorText, setErrorText] = useState('')
	const [refresh, setRefresh] = useState(0)

	// let value
	// if (editor.getEditValue) value = editor.getEditValue()
	// if (typeof value == 'undefined') value = provider.getValue(data) ?? ''
	const value = provider.getValue(data) ?? ''

	const setValue = useCallback(
		async (val: any) => {
			if (validate) {
				const vr = await validate(val)
				if (vr.preventChange) return
				setIsError(vr.isError || false)
				setErrorText(vr.errorText || '')
			}

			provider.setValue(data, val)
			if (onChange) onChange(val)
			// setInternalValue(val)
			if (dataChanged) dataChanged(data)
			setRefresh((v) => v + 1)
		},

		[data, dataChanged, onChange, provider, validate]
	)

	return {
		baseProps: {
			label: name,
			helperText: errorText,
			error: isError,
			disabled: getValueFromFunction(editor.disabled),
			sx
		},
		value,
		setValue
	}
}

export function useTextFieldEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const { baseProps, value, setValue } = useFieldEditor(props)
	return {
		...baseProps,
		value,
		onChange: (e: any) => {
			setValue(e.target.value)
		},
		autoComplete: 'off',
		fullWidth: true
	}
}
export function useCheckboxFieldEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const { baseProps, value, setValue } = useFieldEditor(props)
	const onChange = useCallback(
		(e: any) => {
			setValue(e.target.checked)
		},
		[setValue]
	)

	return {
		...baseProps,
		checked: isTrue(value),
		onChange
	}
}
export function useItemSelectorFieldEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const { fieldDef } = props
	const { baseProps, value, setValue } = useFieldEditor(props)
	const { editor } = fieldDef
	const allItems = editor.context.allItems || []

	const onChange = useCallback(
		async (e: any, val: any, reason: any) => {
			// if the value is an object, then try to use a property of the object as the value
			if (typeof val == 'object' && val != null) val = val.value || val.id || val.label
			await setValue(val)
			log('reason', reason, val)
		},
		[setValue]
	)
	let isOptionEqualProps = {}
	if (allItems.length > 0 && typeof allItems[0] == 'object') {
		isOptionEqualProps = {
			isOptionEqualToValue: (option: any, value: any) => {
				const optValue = option.value || option.id || option.label
				return optValue == value
			}
		}
	}

	return {
		...baseProps,
		value,
		onChange,
		fullWidth: true,
		...isOptionEqualProps
	}
}
export function useStringListEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const { baseProps, value, setValue } = useFieldEditor(props)
	const displayValue = Array.isArray(value) ? value.join(',') : value

	const editableString = value?.length ? value.join('\n') : ''

	const internalSetValue = useCallback(
		(val: string) => {
			let list: string[] = val.trim().split('\n')
			list = list.map((item) => item.trim().replace(/\r/g, ''))
			setValue(list)
		},
		[setValue]
	)
	return {
		...baseProps,
		displayValue,
		value: editableString,
		setValue: internalSetValue,
		disabled: true
	}
}
