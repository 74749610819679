import React, { useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { ButtonType, Flex, FlexC, FlexR, ICustomDialogCallbackProps, OK, Text, useModal } from '@app/muiplus'
import { Box, Popper, TextField, useTheme } from '@mui/material'
import { WebEditorService, useWebEditorService } from 'src/services/WebEditor/WebEditorService'
import { useCurrentRef } from '@app/hooks'

const log = anylogger('PromptForNewPageName')

interface PromptForNewPageNameProps extends ICustomDialogCallbackProps {
	parentPath: string
}
export default function PromptForNewPageName(props: PromptForNewPageNameProps) {
	const { parentPath, dataChanged, setHooks } = props
	const [order, setOrder] = useState(undefined)
	const [name, setName] = useState('')
	const [showChildren, setShowChildren] = useState(false)

	const trimNumberPrefix = useCallback((str?: string): string => {
		if (!str) return ''
		const matches = str.match(/[0-9]*\-(.*)/)
		const res = matches ? matches[1] : str
		return res
	}, [])
	let parts = parentPath.split('/')
	parts = parts.map((p) => trimNumberPrefix(p))
	let parentDir = parts.pop()
	parts.push(parentDir!)

	const svc = useWebEditorService()

	const [ref, setRef] = useCurrentRef<HTMLDivElement>()

	const [childPages, setChildPages] = useState<string[]>([])

	const { alertDialog } = useModal()

	useEffect(() => {
		svc.getChildPages(parentPath).then((existingPages) => {
			setChildPages(existingPages.filter((page) => !page.startsWith('index')))
		})
	}, [parentPath, svc])

	useEffect(() => {
		if (!dataChanged) return
		if (!order || !name) return
		const newName = `${order}-${name}`
		if (!order || !name) return dataChanged('')
		if (!dataChanged) return

		dataChanged(parentPath + '/' + newName)
	}, [dataChanged, name, order, parentPath])
	const orderChanged = useCallback((e: any) => {
		setOrder(e.target.value)
	}, [])
	const nameChanged = useCallback((e: any) => {
		setName(e.target.value)
	}, [])
	const onFinish = useCallback(
		async (buttonId: ButtonType, data: string) => {
			if (buttonId != OK) return
			if (!data) {
				alertDialog({ title: 'Error', content: 'No name specified' })
				return { preventClose: true }
			}

			return
		},
		[alertDialog]
	)

	useEffect(() => {
		if (setHooks) setHooks(onFinish)
	}, [onFinish, setHooks])
	const onFocus = useCallback(() => {
		setShowChildren(true)
	}, [])
	const onBlur = useCallback((props: any) => {
		setShowChildren(false)
	}, [])
	const theme = useTheme()
	return (
		<FlexR ref={setRef} scroll={false}>
			<Popper
				open={showChildren}
				anchorEl={ref}
				placement="bottom"
				sx={{
					zIndex: 10000
				}}
			>
				<FlexC
					maxWidth={600}
					sx={{
						borderWidth: '1px',
						borderColor: 'black',
						borderStyle: 'solid',
						borderRadius: '0.5em',
						backgroundColor: theme.palette.secondary.main,
						boxShadow: 24,
						p: '0.25em',
						mt: '0.25em'
					}}
				>
					<div>
						The number you select will dictate the order of the page in its parent. If adding a new page it is good to go up by
						10 so that there is room to add another page in the middle.
					</div>
					<div>
						The following pages (with numbers) already exist in <b>{`${parentDir}`}</b>.
					</div>
					{childPages.map((page, idx) => (
						<b key={idx}>{page}</b>
					))}
				</FlexC>
			</Popper>
			<Text my="auto">{parts.join('/')}</Text>
			<TextField
				type="number"
				label="Order"
				value={order}
				sx={{
					width: '100px'
				}}
				autoComplete="off"
				onChange={orderChanged}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			<TextField label="New Page Name" value={name} onChange={nameChanged} autoComplete="off" />
		</FlexR>
	)
}
