import anylogger from '@app/anylogger'
import { toString } from './StrUtils'
import { usePreviousState } from './usePreviousState'

const log = anylogger('logRenderReason')

export const logRenderReason = (compName: string, props: any) => {
	let lines: string[] = []

	Object.entries(props).forEach((p) => {
		const prev = usePreviousState(p[1])
		if (p[1] !== prev) {
			const old = toString(prev).slice(0, 100)
			const newVal = toString(p[1]).slice(0, 100)
			lines.push(`${p[0]}: ${old}->${newVal}`)
		}
	})
	if (lines.length) {
		log(compName, 'PropsChanged')
		lines.forEach((line) => {
			log('    ' + line)
		})
	}
}
