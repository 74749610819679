import React, { useMemo, useContext } from 'react'
import anylogger from '@app/anylogger'
import { ClientProxy } from '@app/clientserverutils'
import { IGoogleCalendar } from '@app/googlecalendar'
import { IMdxSource, IParsedMdx } from '@app/mdxsource'
import { adjustDate, formatDateTime, WebMenu } from '@app/utils'
import { Octokit } from '@octokit/rest'
import { GithubContent } from './WebEditorApi'
import { IWebEditorApi } from './IWebEditorApi'
import { CalendarPageLink } from 'components/CalendarCards'
import { getMemberMenu } from '../getMemberMenu'

const log = anylogger('WebEditorService')

export interface CachedPage {
	page: string
	origContent: IParsedMdx
	sha: string
	parsedContent: IParsedMdx
	isNew: boolean
}

class Service {
	private mdxSource: IMdxSource
	private storageKey: string = 'PendingPages'
	webEditorApi: IWebEditorApi

	constructor() {
		this.mdxSource = new ClientProxy(new IMdxSource(), '/api/mdxContent') as IMdxSource
		this.webEditorApi = new ClientProxy(new IWebEditorApi(), '/api/webEditor') as IWebEditorApi
	}
	getEvents(startDate: string, endDate: string) {
		return this.webEditorApi.getEvents(startDate, endDate)
	}
	async getCachedPage(page: string, isNew: boolean): Promise<CachedPage> {
		try {
			if (typeof localStorage == 'undefined')
				throw new Error('WebEditorService cannot be used on the server. It requires localStorage')

			let pendingPages = this.getPendingPages()
			const ghContent = isNew ? { content: '', sha: '' } : await this.getGithubContent(page)

			const retrieved = await this.mdxSource.ParseContent(ghContent.content)

			const key: string = this.getPageKey(page)

			let cached: CachedPage | undefined = pendingPages[key]
			if (!cached) {
				cached = { page, isNew, origContent: { ...retrieved }, parsedContent: retrieved, sha: ghContent.sha }

				pendingPages[key] = cached
				localStorage.setItem(this.storageKey, JSON.stringify(pendingPages))
			}
			return cached
		} catch (error) {
			// If error also return initialValue
			console.error('Error getting localstorage key:', this.storageKey, error)
			throw error
		}
	}
	getPendingPages(): Record<string, CachedPage> {
		let pendingPages: Record<string, CachedPage> = {}
		const cachedStorage = localStorage.getItem(this.storageKey)
		if (cachedStorage) pendingPages = JSON.parse(cachedStorage)
		return pendingPages
	}

	async saveParsedContent(page: string, cachedPage: CachedPage) {
		const key: string = this.getPageKey(page)
		let pendingPages = this.getPendingPages()
		pendingPages[key] = cachedPage
		this.savePendingPages(pendingPages)
	}

	private savePendingPages(pendingPages: any) {
		localStorage.setItem(this.storageKey, JSON.stringify(pendingPages))
	}

	getPageKey(page: string) {
		return page.toLocaleLowerCase().replace(/ /g, '')
	}

	async getGithubContent(page: string): Promise<GithubContent> {
		return this.webEditorApi.getContent(page)
	}
	hasPendingChanges(page: string) {
		if (typeof localStorage == 'undefined') throw new Error('WebEditorService cannot be used on the server. It requires localStorage')
		const key: string = this.getPageKey(page)
		let pendingPages = this.getPendingPages()

		let cached = pendingPages[key]
		return typeof cached != 'undefined'
	}
	hasChanges(data: CachedPage | undefined) {
		if (!data) return false
		const origMeta = JSON.stringify(data.origContent.meta)
		const newMeta = JSON.stringify(data.parsedContent.meta)
		return origMeta !== newMeta || data.origContent.content !== data.parsedContent.content
	}
	async getParsedContent(rawContent: string): Promise<IParsedMdx> {
		return await this.mdxSource.ParseContent(rawContent)
	}
	async getJoinedContent(content: IParsedMdx): Promise<string> {
		return await this.mdxSource.JoinContent(content)
	}
	async savePage(page: string, current: CachedPage, name: string, email: string, status: (status: string) => void) {
		if (status) status('Verifying...')
		const content = await this.mdxSource.JoinContent(current.parsedContent)
		let pendingPages = this.getPendingPages()
		const key: string = this.getPageKey(page)
		let cached = pendingPages[key]
		if (!cached) throw new Error(`Could not find the cached page ${page}`)

		let sha
		if (!current.isNew) {
			const ghContent = await this.getGithubContent(page)
			sha = ghContent.sha
			if (ghContent.sha != cached.sha)
				throw new Error(
					`The page ${page} has been updated by someone else since you last started editing the page. Please cancel and make your changes again.`
				)
		}
		let path = page
		if (path.startsWith('/')) path = path.substring(1)

		if (status) status('Saving...')
		await this.webEditorApi.savePage(path, content, name, email, sha)
		let url
		if (!current.isNew) {
			const entry = await this.mdxSource.FindEntryByString(page)
			if (!entry) throw new Error(`Could not find the MdxFile for ${page}`)
			url = entry.urlPath
		} else {
			url = await this.webEditorApi.getUrlFromPath(page)
		}

		if (status) status('Updating Server File...')

		await this.webEditorApi.updateServerContent(page, content)

		if (current.isNew) {
			await this.rebuildAllPages(status)
		} else {
			if (status) status('Regenerating Page...')

			await this.rebuildUrl(url)
		}

		await this.cancelPage(page)

		if (status) status('Done')
		return url
	}
	async deletePage(url: string, name: string, email: string, status: (status: string) => void) {
		const page = await this.getFullPath(url)
		await this.webEditorApi.deletePage(page, name, email)

		await this.rebuildAllPages(status)
	}

	async archivePage(url: string, newPageName: string, name: string, email: string, status: (status: string) => void) {
		const page = await this.getFullPath(url)
		const archivePage = await this.getFullPath('/Archive')
		const dirs = url.split('/')
		dirs.pop()
		const newPage = archivePage + dirs.join('/') + '/' + newPageName
		log('newPage', newPage)

		const ghContent = await this.getGithubContent(page)
		log('ghContent', ghContent)

		await this.webEditorApi.savePage(newPage, ghContent.content, name, email, ghContent.sha, `Archiving page ${page}`)

		await this.webEditorApi.deletePage(page, name, email, `Archived page ${page}`)
		await this.webEditorApi.updateServerContent(newPage, ghContent.content)
		await this.rebuildAllPages(status)
	}

	private async rebuildUrl(url: string) {
		log('revalidating', url)
		const params = { path: url }

		const options = {
			method: 'POST',
			body: JSON.stringify(params)
		}

		const res = await fetch('/api/revalidate', options)
		if (!res.ok) {
			// this seems to error out when adding a new page
			log.error(`Error revalidating ${url}: ${res}`)
		}
	}

	async rebuildAllPages(status: (status: string) => void) {
		async function rebuild(menu: WebMenu) {
			for (let ii = 0; ii < menu.length; ii++) {
				const item = menu[ii]
				if (status) status(`Revalidating ${item.url}`)
				if (item.url) await self.rebuildUrl(item.url)
				if (item.children) await rebuild(item.children)
			}
		}
		const menu = await this.mdxSource.GetMenu()
		const self = this
		await rebuild(menu)
		const memberMenu = await this.webEditorApi.getMemberMenu()
		await rebuild(memberMenu)
	}

	async cancelPage(page: string): Promise<string> {
		const key: string = this.getPageKey(page)
		let pendingPages = this.getPendingPages()
		delete pendingPages[key]
		this.savePendingPages(pendingPages)
		const entry = await this.mdxSource.FindEntryByString(page)
		return entry?.urlPath ? entry.urlPath : '/Home'
	}
	async getAllWebPages(): Promise<string[]> {
		const res = await this.mdxSource.GetPaths()
		const list = res.map((path) => path.join('/'))
		return list
	}
	async getAllFiles(): Promise<string[]> {
		const res = await this.mdxSource.GetAllFiles()

		return res
	}
	GetSinglePageData(location: string) {
		return this.mdxSource.GetSinglePageData(location)
	}
	async getPreviewData(page: string, data: IParsedMdx): Promise<{ source: any; meta: any }> {
		let { content, meta } = data
		content = await this.mdxSource.ApplyPrependedContent(content, meta)

		const entry = await this.mdxSource.FindEntryByString(page)
		let scope: any = {}
		if (entry) {
			const childData = await this.mdxSource.GetChildData(entry)
			scope.childData = childData
		}

		const pageData = await this.mdxSource.GetPageData(meta.pageData)
		if (Array.isArray(pageData)) scope.pageData = pageData

		if (meta.summaryImage) scope.summaryImage = meta.summaryImage

		scope.stringData = meta.stringData ? meta.stringData : []

		const source = await this.mdxSource.GetSerializedContent(content, scope)
		return { source, meta }
	}
	async getChildPages(page: string): Promise<string[]> {
		const entry = await this.mdxSource.FindEntryByString(page)
		if (!entry?.children) return []
		return entry.children.map((entry) => entry.fullPath.slice(page.length + 1))
	}

	async getFullPath(urlPath: string): Promise<string> {
		const entry = await this.mdxSource.FindEntryByString(urlPath)
		if (!entry) throw new Error(`Could not find the entry for ${urlPath}`)
		return entry.fullPath
	}
	async getUrlFromPath(page: string): Promise<string> {
		return await this.webEditorApi.getUrlFromPath(page)
	}
}

export const WebEditorServiceContext = React.createContext<Service>(undefined!)

export function useWebEditorService(): Service {
	const context = useContext(WebEditorServiceContext)
	return context
}

export const WebEditorService = (props: any) => {
	const service = useMemo(() => new Service(), [])

	return <WebEditorServiceContext.Provider value={service}>{props.children}</WebEditorServiceContext.Provider>
}
