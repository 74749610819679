import { useSession as authSession, SessionContextValue } from 'next-auth/react'
import anylogger from '@app/anylogger'
import { useEffect, useState } from 'react'
import { Session } from 'next-auth'

const log = anylogger('useSession')

interface CustomSession extends Session {
	roles: string[]
}
export function useSession(): [CustomSession | undefined, boolean] {
	const [session, setSession] = useState<any>(undefined)
	const [loading, setLoading] = useState(true)

	const us = authSession()
	const { data: sess, status } = us
	const debugLogin = true

	useEffect(() => {
		if (process.env.NODE_ENV === 'production' || debugLogin) {
			setSession(sess)
			setLoading(status == 'loading')
		} else {
			setSession({
				user: { name: 'Debug', email: 'test@not.ca' },
				roles: ['WebEditor', 'PictureEditor', 'CalendarEditor', 'FileEditor', 'WebMaster']
			})
			setLoading(false)
		}
	}, [debugLogin, sess, status])

	return [session, loading]
}
