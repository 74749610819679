import { zeroPad } from './StrUtils'
import anylogger from '@app/anylogger'

const log = anylogger('dateUtils')

/***
 * date.toISOString() will produce an RFC3339 date
 * new Date(str) will parse the date string (especially an ISO date, but also other formats) to a date object
 */

export interface DateTime {
	yyyy: number
	yy: number
	mm: number
	mmm: string
	mmmm: string
	dd: number
	hh: number
	nn: number
	ss: number
	zzz: number
	wd: number
	wds: string
	wdl: string
}

export const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const longMonths = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
]
export const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const longDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export function normalizeTime(date: Date | string | number) {
	if (typeof date == 'string') {
		if (date.indexOf('T') < 0) date = date + 'T00:00'
	}
	return new Date(date)
}
export const parseDateTime = (date: Date | string): DateTime => {
	date = normalizeTime(date)
	const dateTime = {
		mmmm: longMonths[date.getMonth()],
		mmm: shortMonths[date.getMonth()],
		yyyy: date.getFullYear(),
		yy: date.getFullYear() % 100,
		mm: date.getMonth() + 1,
		dd: date.getDate(),
		hh: date.getHours(),
		HH: date.getHours() % 12,
		AM: date.getHours() < 12 ? 'AM' : 'PM',
		PM: date.getHours() < 12 ? 'AM' : 'PM',
		nn: date.getMinutes(),
		ss: date.getSeconds(),
		ms: date.getMilliseconds(),
		zzz: date.getMilliseconds(),
		wds: shortDays[date.getDay()],
		wdl: longDays[date.getDay()],
		wd: date.getDay()
	}
	return dateTime
}
/**
 * This function returns a formatted date string based on the format param.
 * @param date the date to format
 * @param format The format string, which acceptsthe following format codes:
 * * yyyy - 4 digit year
 * * yy - 2 digit year
 * * mmmm - full month name
 * * mmm - short month name
 * * mm - month number
 * * dd - day of month
 * * hh - hours (24 hour clock)
 * * HH - hours (12 hour clock)
 * * AM - AM or PM
 * * PM - AM or PM
 * * nn - minutes
 * * ss - seconds
 * * zzz - milliseconds
 * * wd - day of week number
 * * wds - short day of week name
 * * wdl - long day of week name
 * @example formatDateTime(new Date(), 'yyyy-mm-dd hh:nn:ss') -> '2021-07-01 12:34:56'
 * @example formatDateTime(new Date(), 'mmm dd, yyyy') -> 'Jul 01, 2021'
 * @returns The formatted date
 */
export const formatDateTime = (date: Date | string | number, format: string = 'yyyy-mm-dd hh:nn:ss') => {
	if (!date) return ''
	let dt: Date
	dt = normalizeTime(date)

	if (!dt) return ''
	const dateTime = parseDateTime(dt)

	let res = format
	for (let [key, value] of Object.entries(dateTime)) {
		res = res.replace(key, zeroPad(value, 2))
	}
	return res
}

export function daysDiff(first: Date, second: Date) {
	// Take the difference between the dates and divide by milliseconds per day.
	// Round to nearest whole number to deal with DST.
	const d1 = first as unknown as number
	const d2 = second as unknown as number
	return Math.round((d2 - d1) / (1000 * 60 * 60 * 24))
}
export function secsDiff(first: Date, second: Date) {
	// Take the difference between the dates and divide by milliseconds per day.
	// Round to nearest whole number to deal with DST.
	const d1 = first as unknown as number
	const d2 = second as unknown as number
	return Math.round((d2 - d1) / 1000)
}
export function adjustDate(date: Date | string, days: number) {
	const res = normalizeTime(date)
	res.setDate(res.getDate() + days)
	return res
}
export function adjustMonth(date: Date | string, months: number) {
	const res = normalizeTime(date)
	res.setMonth(res.getMonth() + months)
	return res
}
export function adjustHours(date: Date | string, hours: number) {
	const res = normalizeTime(date)
	res.setHours(res.getHours() + hours)
	return res
}
export function adjustMinutes(date: Date | string, minutes: number) {
	const res = normalizeTime(date)
	res.setMinutes(res.getMinutes() + minutes)
	return res
}
export function adjustSeconds(date: Date | string, seconds: number) {
	const res = normalizeTime(date)
	res.setSeconds(res.getSeconds() + seconds)
	return res
}
export function adjustMilliseconds(date: Date | string, milliSeconds: number) {
	const res = normalizeTime(date)
	res.setMilliseconds(res.getMilliseconds() + milliSeconds)
	return res
}
export function datesAreEqual(date1: DateTime, date2: DateTime) {
	return date1.yy == date2.yy && date1.mm == date2.mm && date1.dd == date2.dd
}
export function toLocaleISOFormat(date: string | number | Date, time: string = '00:00:00') {
	const dt = normalizeTime(date)
	const [hours, minutes, seconds] = time.split(':').map(Number)

	dt.setHours(hours || 0, minutes || 0, seconds || 0)

	const str = formatDateTime(dt, 'yyyy-mm-dd') + 'T' + formatDateTime(dt, 'hh:nn:ss')
	const timezoneOffset = -dt.getTimezoneOffset()
	const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0')
	const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0')
	const offsetSign = timezoneOffset >= 0 ? '+' : '-'
	const timezoneOffsetStr = `${offsetSign}${offsetHours}:${offsetMinutes}`

	return str + timezoneOffsetStr
}
