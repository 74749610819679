import { openDB, deleteDB, IDBPDatabase } from 'idb'
import { LogLevel } from './anylogger'

function log(...args: any) {
	return
	if (typeof window != 'undefined') {
		console.log('DBLogger', ...args)
	}
}

export const DBName = 'DBLogger'
const DBVersion = 1
const TableName = 'Logs'

interface LogEntry {
	ts: number
	logLevel: LogLevel
	name: string
	args: any
}
export class DBLogger {
	dbPromise?: Promise<IDBPDatabase<LogEntry>>
	queue: LogEntry[]
	processing: boolean = false
	tableName: string
	constructor() {
		this.queue = []
		this.tableName = TableName
		if (typeof window != 'undefined') this.dbPromise = this.createDatabase()
		// log('DBLogger constructor')

		const dt = new Date().toDateString()
		const today = new Date(dt)
		const targetDate = today.setDate(today.getDate() - 3)
		this.deleteRecordsOlderThan(new Date(targetDate))
	}
	destroy() {
		if (this.dbPromise) {
			this.dbPromise.then((db) => {
				db.close()
			})
		}
	}
	log(logLevel: LogLevel, name: string, ...args: any): void {
		if (!this.dbPromise) return
		this.queue.push({ ts: Date.now(), logLevel, name, args })
		this.processQueue()
	}
	async processQueue() {
		if (!this.dbPromise) return
		if (this.processing) return
		let processed = 0
		this.processing = true
		try {
			const db = await this.dbPromise
			const tran = db.transaction(this.tableName, 'readwrite')
			const store = tran.objectStore(this.tableName)
			log('Processing queue:', this.queue.length)

			while (this.queue.length) {
				const entry = this.queue.shift()
				if (entry) {
					processed++
					try {
						const textEntry = {
							ts: entry.ts,
							timeStr: new Date(entry.ts).toISOString(),
							logLevel: entry.logLevel,
							name: entry.name,
							args: this.parseObject(entry.args)
						}
						await store.put(textEntry)
					} catch (err) {
						console.error('DBLogger', 'error saving log entry', err, entry)
					}
				}
			}
			await tran
		} finally {
			this.processing = false
			if (this.queue.length) {
				console.warn('DBLogger', 'There are still some items in the queue:', this.queue.length)
			} else {
				log('Finished processing queue: ', processed)
			}
		}
	}
	parseObject(args: any, level: number = 0): any {
		if (level > 5) {
			return 'possible recursion...'
		}
		if (typeof args == 'function') return args.toString()
		if (Array.isArray(args)) {
			return args.map((arg) => this.parseObject(arg, level + 1))
		}
		if (args == null) return ''
		if (typeof args != 'object') return args

		return Object.getOwnPropertyNames(args).reduce((res, key) => {
			res[key] = this.parseObject(args[key], level + 1)
			return res
		}, {} as any)
	}
	async getAll(): Promise<LogEntry[]> {
		if (!this.dbPromise) return []
		const db = await this.dbPromise
		const all = await db.getAll(this.tableName)
		return all
	}

	async getNewestRecord(): Promise<LogEntry | undefined> {
		if (!this.dbPromise) return
		const db = await this.dbPromise
		let cursor = await db.transaction(this.tableName).store.index('ts').openCursor(/*query*/ null, /*direction*/ 'prev')
		if (cursor) {
			return cursor.value
		} else {
			return undefined
		}
	}
	async getOldestRecord(): Promise<LogEntry | undefined> {
		if (!this.dbPromise) return
		const db = await this.dbPromise
		let cursor = await db.transaction(this.tableName).store.index('ts').openCursor()
		if (cursor) {
			return cursor.value
		} else {
			return undefined
		}
	}
	async getRecordsOlderThan(date: Date): Promise<LogEntry[]> {
		if (!this.dbPromise) return []
		const dt = new Date(date)
		const db = await this.dbPromise

		const range = IDBKeyRange.upperBound([dt.getTime(), 1])
		let cursor = await db.transaction(this.tableName).store.index('ts').openCursor(range)
		let count = 0
		let res = []
		while (cursor) {
			log(cursor.value.timeStr)
			res.push(cursor.value)
			cursor = await cursor.continue()
			count++
		}

		return res
	}
	async getRecordsForDate(date: Date): Promise<LogEntry[]> {
		if (!this.dbPromise) return []
		const dt = new Date(date.getFullYear(), date.getMonth(), date.getDate())
		const db = await this.dbPromise
		const range = IDBKeyRange.lowerBound([dt.getTime(), -1])
		let cursor = await db.transaction(this.tableName).store.index('ts').openCursor(range)
		let count = 0
		let res = []
		while (cursor) {
			res.push(cursor.value)
			cursor = await cursor.continue()
			count++
		}
		return res
	}
	async deleteRecordsOlderThan(date: Date): Promise<void> {
		const dt = new Date(date)
		log('Deleting records older than', dt)
		if (!this.dbPromise) return
		const db = await this.dbPromise
		const range = IDBKeyRange.upperBound([dt.getTime(), 1])
		let cursor = await db.transaction(this.tableName, 'readwrite').store.index('ts').openCursor(range)

		let count = 0
		while (cursor) {
			cursor.delete()
			cursor = await cursor.continue()
			count++
		}
		log('deleted: ', count)
	}

	private async createDatabase() {
		const self = this
		return openDB<LogEntry>(DBName, DBVersion, {
			upgrade(db, oldVersion, newVersion, transaction) {
				console.warn(`upgrading database from ${oldVersion} to ${newVersion}`)
				let logs
				if (oldVersion < 1) {
					logs = db.createObjectStore(self.tableName, { keyPath: 'id', autoIncrement: true })
					logs.createIndex('ts', ['ts', 'id'])
				}
				if (oldVersion < 2) {
					// Add new db versions here
				}
			}
		})
	}
}
