import { alpha, Box, IconButton, styled, SvgIconProps, Typography, useTheme, SxProps } from '@mui/material'
import {
	TreeItem2Content,
	TreeItem2GroupTransition,
	TreeItem2Icon,
	TreeItem2IconContainer,
	TreeItem2Provider,
	TreeItem2Root,
	useTreeItem2,
	UseTreeItem2Parameters
} from '@mui/x-tree-view'
import clsx from 'clsx'
import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import Edit from '@mui/icons-material/Edit'

import anylogger from '@app/anylogger'
import { Flex, FlexR } from './Flex'

const log = anylogger('TreeItem')

interface StyledTreeItemProps extends Omit<UseTreeItem2Parameters, 'rootRef'>, React.HTMLAttributes<HTMLLIElement> {
	labelIcon?: React.ElementType<SvgIconProps>
	labelInfo?: string
	endIcon?: React.JSX.Element
	onlyShowEndIconOnHover?: boolean
	sxProps?: SxProps
}
export const CustomTreeItem = React.forwardRef(function CustomTreeItem(props: StyledTreeItemProps, ref: React.Ref<HTMLLIElement>) {
	const theme = useTheme()
	const [showEndIcon, setShowEndIcon] = useState(false)
	const [adjustedEndIcon, setAdjustedEndIcon] = useState<ReactElement | null>(null)

	const {
		id,
		itemId,
		label,
		disabled,
		children,
		labelIcon: LabelIcon,
		labelInfo,
		endIcon,
		onlyShowEndIconOnHover = false,
		sxProps,
		...other
	} = props

	const { getRootProps, getContentProps, getIconContainerProps, getLabelProps, getGroupTransitionProps, status, publicAPI } =
		useTreeItem2({
			id,
			itemId,
			children,
			label,
			disabled,
			rootRef: ref
		})

	const contentProps = getContentProps({
		className: clsx('content', {
			expanded: status.expanded,
			selected: status.selected,
			focused: status.focused
		})
	})
	const rootProps = getRootProps({ ...other })

	useEffect(() => {
		setAdjustedEndIcon(
			endIcon ? (
				<Flex position="absolute" right="0px">
					{endIcon}
				</Flex>
			) : null
		)
	}, [endIcon])
	const iconClick = useCallback(
		(event: SyntheticEvent<Element, Event>) => {
			publicAPI.setItemExpansion(
				// The DOM event that triggered the change
				event,
				// The id of the item to expand or collapse
				itemId,
				// If `true` the item will be expanded
				// If `false` the item will be collapsed
				!status.expanded
			)
		},
		[itemId, publicAPI, status.expanded]
	)

	const containerClick = useCallback((e: any) => {
		// do nothing.  we implement in  contentClick
		// this prevents an item from being selected if you just click the expand/collapse icon
	}, [])
	const contentClick = useCallback(
		(e: any) => {
			contentProps.onClick(e)
		},
		[contentProps]
	)

	return (
		<TreeItem2Provider itemId={itemId}>
			<CustomTreeItemRoot {...rootProps}>
				<CustomTreeItemContent
					{...contentProps}
					onClick={containerClick}
					onMouseEnter={() => setShowEndIcon(true)}
					onMouseLeave={() => setShowEndIcon(false)}
				>
					<CustomTreeItemIconContainer {...getIconContainerProps()} onClick={iconClick}>
						<TreeItem2Icon status={status} />
					</CustomTreeItemIconContainer>
					<FlexR fill alignItems="center" onClick={contentClick} position="relative">
						{LabelIcon && <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />}
						<Typography
							{...getLabelProps({
								variant: 'body2',
								sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1, ...sxProps }
							})}
						/>
						<Typography variant="caption" color="inherit">
							{labelInfo}
						</Typography>
						{adjustedEndIcon && (!onlyShowEndIconOnHover || showEndIcon) && adjustedEndIcon}
					</FlexR>
				</CustomTreeItemContent>
				{children && <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />}
			</CustomTreeItemRoot>
		</TreeItem2Provider>
	)
})
const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
	color: theme.palette.text.secondary
}))

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
	borderRadius: theme.spacing(1),
	fontWeight: theme.typography.fontWeightMedium,
	[':hover']: {
		backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)
	},
	'&.focused': {
		backgroundColor: 'inherit',
		[':hover']: {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)
		}
	},
	'&.selected': {
		backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
		[':hover']: {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity)
		}
	}
}))

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
	// marginRight: theme.spacing(1)
}))

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(({ theme }) => ({
	marginLeft: 0,
	[`& .content`]: {
		paddingLeft: theme.spacing(1)
	}
}))
