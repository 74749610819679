import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { equals, logRenderReason } from '@app/utils'

const log = anylogger('useLocalStorage')

/**
 * This is a simple wrapper around the browser's localStorage API.
 * @param key The key for the local storage
 * @param initialValue The initial value of the storage
 * @returns [storedValue, setStoredValue], similar to the useState hook.
 */
export function useLocalStorage<T = any>(key: string | undefined, initialValue: T): [T, Dispatch<SetStateAction<T>>] {
	// State to store our value
	const [storedValue, setStoredValue] = useState<T>(initialValue)

	// Pass initial state function to useState so logic is only executed once
	useEffect(() => {
		if (!key) return
		if (typeof localStorage == 'undefined') return
		try {
			// this is done in useEffect so that we don't get an error if we are using SSR, because localStorage does not exist there

			// Get from local storage by key
			const item = localStorage.getItem(key)
			// only set the value if it is returned from storage.  No need to worry about initialValue because it will already be set in useState.
			if (item != null) {
				const val = JSON.parse(item)
				setStoredValue(val)
			}
			// }
		} catch (error) {
			// If error also return initialValue
			console.error('Error getting localstorage key:', key, error)
		}
	}, [key])

	// Return a wrapped version of useState's setter function that
	// persists the new value to localStorage.
	const setValue = useCallback(
		(value: SetStateAction<T>) => {
			try {
				if (!key) {
					console.error('You are trying to persist to local storage, but a key has not been defined.')
					return
				}
				// Allow value to be a function so we have the same API as useState
				const valueToStore = value instanceof Function ? value(storedValue) : value
				// Save state
				setStoredValue(valueToStore)
				// Save to local storage
				localStorage.setItem(key, JSON.stringify(valueToStore))
			} catch (error) {
				// A more advanced implementation would handle the error case
				console.log(error)
			}
		},
		[key, storedValue]
	)

	return [storedValue, setValue]
}
