import { DBLogger } from '@app/anylogger'
import { ClientProxy } from '@app/clientserverutils'
import { useModal } from '@app/muiplus'
import { formatDateTime } from '@app/utils'
import { Button } from '@mui/material'
import { useCallback, useState } from 'react'
import { useSession } from 'src/services/useSession'
import { IWebEditorApi } from 'src/services/WebEditor/IWebEditorApi'
import anylogger from '@app/anylogger'
import JSZip from 'jszip'

const log = anylogger('SendErrorReport')

export async function sendDebugEmail(user: string, email?: string) {
	if (!user) user = 'Unknown User'
	if (!email) email = 'Unknown Email'

	const api = new ClientProxy(new IWebEditorApi(), '/api/webEditor') as IWebEditorApi
	const logger = new DBLogger()
	const logs = await logger.getRecordsForDate(new Date())
	const logStr = JSON.stringify(logs)

	const zip = new JSZip()
	zip.file(`Logs ${formatDateTime(new Date(), 'yyyy-mm-dd hh:nn:ss')} - ${user}-${email}.log`, logStr)
	const content = await zip.generateAsync({ type: 'base64' })

	await api.sendEmail('webmaster@kc7070.ca', email, `Log files from ${user}`, `Log Files from ${user}-${email}`, [
		{ filename: `Logs ${formatDateTime(new Date(), 'yyyy-mm-dd hh:nn:ss')} - ${user}-${email}.zip`, content, encoding: 'base64' }
	])
}

interface SendErrorReportProps {}
function SendErrorReport(props: SendErrorReportProps) {
	const [session, loading] = useSession()
	const email = session?.user?.email
	const name = session?.user?.name
	const { alertDialog, busyDialog } = useModal()
	const sendDebugLog = useCallback(async () => {
		const { cancel } = busyDialog({ title: 'Sending Debug Email', content: 'Sending...' })

		try {
			await sendDebugEmail(name || '', email || '')
			await cancel()
			alertDialog({
				title: 'Email Sent',
				content: 'The email was sent successfully.'
			})
		} catch (err) {
			log('Error sending debug email', err)
			await cancel()
			alertDialog({
				title: 'Error Sending Debug Email',
				content: JSON.stringify(err)
			})
		}
	}, [alertDialog, busyDialog, email, name])

	return (
		<Button
			onClick={sendDebugLog}
			sx={{
				mx: 'auto"'
			}}
		>
			Send Error Report
		</Button>
	)
}
export default SendErrorReport
