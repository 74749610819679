import React, { useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { TreeItem, TreeView, MultiSelectTreeViewProps } from '@mui/lab'
import { equals, getValueFromFunction, TreeIterator, ValueOrFunction } from '@app/utils'
import { IUIItemAdapter } from './UIItemAdapter'
import { Fade, styled, useThemeProps } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'
import AutorenewOutlined from '@mui/icons-material/AutorenewOutlined'
import { TreeItemContentOnlyIconExpand } from './TreeItemContentOnlyIconExpand'
import { useLocalStorageOrState } from '@app/hooks'

const log = anylogger('DataTreeView')

export interface ITreeNodeActions {
	// This triggers a refresh of the node in case something changed.
	refresh: () => void
	setExpanded: (isExpanded: boolean) => void
	getExpanded: () => boolean
}

export interface ITreeNodeData extends IUIItemAdapter {
	style?: ValueOrFunction<any>
	getChildren: () => Promise<ITreeNodeData[]>
	setActions?: (actions: ITreeNodeActions) => void
	adornments?: ValueOrFunction<React.ReactNode>
}
export type ITreeNodeDataList = ITreeNodeData[]

/**
 * This class is used internally by DataTreeView to translate the ITreeNodeData to a consistend interface
 * to by used by DataTreeItem. i.e. It handles returning a value for id, label, etc. whether or not the ITreeNodeData
 * supplies a value or a function.  That way DataTreeItem just has to call node.label(), etc. to retrieve the value.
 * It also handles querying the child items asynchrounously.
 * */
class TreeNode implements ITreeNodeActions {
	protected mSource: ITreeNodeData
	parent?: TreeNode
	children: Promise<TreeNodeList> | undefined
	isExpanded: boolean = false
	constructor(source: ITreeNodeData, parent?: TreeNode) {
		this.mSource = source
		if (source.setActions) source.setActions(this)
		this.parent = parent
	}
	source = () => getValueFromFunction(this.mSource)
	id = () => getValueFromFunction(this.mSource.id)
	label = () => getValueFromFunction(this.mSource.label)
	style = () => (this.mSource.style ? getValueFromFunction(this.mSource.style) : {})
	adornments(parentHover: boolean): React.ReactNode {
		return this.mSource.adornments ? getValueFromFunction(this.mSource.adornments, parentHover) : null
	}

	getChildren = async (): Promise<TreeNodeList> => {
		if (!this.children) this.children = this.retrieveChildren()
		return this.children
	}
	getLastChild = async (): Promise<TreeNode | undefined> => {
		const children = await this.children
		if (children?.length) return children[children.length - 1]
		else return undefined
	}

	private retrieveChildren = async (): Promise<TreeNodeList> => {
		return this.mSource.getChildren().then((items) => {
			return items.map((item) => {
				return new TreeNode(item, this)
			})
		})
	}

	// ITreeNodeActions
	refresh = () => log.error('This TreeNode is not attached to a view yet')
	setExpanded = (isExpanded: boolean) => {
		// log.error('This TreeNode is not attached to the view yet')
	}

	getExpanded = () => {
		log.error('This TreeNode is not attached to the view yet')
		return false
	}
}
type TreeNodeList = TreeNode[]

interface DataTreeItemProps {
	item: TreeNode
}
const DataTreeItem = React.forwardRef(function TreeNode(props: DataTreeItemProps, ref: any) {
	const { item } = props
	const { restrictExpansionToIcon, getExpanded, setExpanded } = React.useContext(DataTreeContext)

	const [refresh, setRefresh] = useState(0)
	const doRefresh = () => {
		setRefresh(refresh + 1)
	}
	const doGetExpanded = (): boolean => {
		return getExpanded(item.id())
	}
	const doSetExpanded = (isExpanded: boolean) => {
		setExpanded(item.id(), isExpanded)
	}
	// ITreeNodeActions implementation
	item.refresh = doRefresh
	item.setExpanded = doSetExpanded
	item.getExpanded = doGetExpanded

	const [children, setChildren] = useState<undefined | TreeNodeList>(undefined)
	const [refreshIcon, setRefreshIcon] = useState<any | undefined>(<AutorenewOutlined />)

	useEffect(() => {
		if (!item) return
		item.getChildren()
			.then((nodes: TreeNodeList) => {
				setChildren(nodes)
				setRefreshIcon(undefined)
			})
			.catch((err: any) => {
				log.error(err)
			})
	}, [item])

	const renderChildren = () => {
		return (
			children &&
			children.map((child: TreeNode, idx: number) => {
				return <DataTreeItem key={idx} item={child} />
			})
		)
	}
	if (!item) return null

	let contentProp: any = {}
	if (restrictExpansionToIcon) contentProp = { ...contentProp, ContentComponent: TreeItemContentOnlyIconExpand }

	// log('restrictExpansionToIcon', restrictExpansionToIcon, contentProp)

	return (
		<TreeItem nodeId={item.id()} label={item.label()} icon={refreshIcon} {...contentProp}>
			{renderChildren()}
		</TreeItem>
	)
})

class DataTreeContextData {
	restrictExpansionToIcon: boolean = false
	setExpanded: (id: string, isExpanded: boolean) => void = () => {}
	getExpanded: (id: string) => boolean = () => false
}

const DataTreeContext = React.createContext(new DataTreeContextData())

interface DataTreeViewProps extends MultiSelectTreeViewProps {
	/**
	 * The data to be displayed that implements the ITreeNodeData interface */
	data?: ITreeNodeData | ITreeNodeDataList

	/**
	 * this will fill the parent container with the tree view */
	fill?: boolean

	/**
	 * If true, only expands or collapses the node if you click on the icon. */
	restrictExpansionToIcon?: boolean

	/**
	 * Default: undefined. Specifies the local storage key to store the expanded and selected nodes so that they are
	 * retained across browser refreshes.
	 */
	localStorageKey?: string

	/**
	 * If specified, these are the nodeIds of the selected items. Otherwise, the TreeView itself remembers
	 * what is selected, and if localStorageKey is specified, this is persisted across sessions
	 */
	selected?: string[]
	fontSize?: string

	/**
	 * This callback is fired when a tree node item is selected.
	 * data is the original data element supplied to the adapter.
	 * nodeData is the ITreeNodeData item (adapted item)
	 *  */
	onTreeNodeSelected?: (data: any, nodeData: ITreeNodeData) => void
}
/**
 * This is a wrapper around TreeView that allows passing in a data object (or list of objects) that implement ITreeNodeData.
 * This allows you to create an adapter for any tree-like object and display it in the tree view. ITreeNodeData supports an async
 * getChildren method so that children are queried asynchronously.
 *
 * When a node's children are displayed, all those children
 * are queried to see if they have children, so there is a visual indication of those children.
 *
 * The styling is inherited entirely from MUI TreeView/TreeItem.
 */
export const DataTreeView = React.forwardRef(function DataTreeView(inProps: DataTreeViewProps, ref: any) {
	const props = useThemeProps({ props: inProps, name: 'DataTreeView' })

	const {
		data,
		fill,
		sx,
		localStorageKey,
		selected: clientSelected,
		onTreeNodeSelected: onTreeNodeClick,
		restrictExpansionToIcon = false,
		fontSize,
		...rest
	} = props
	const [items, setItems] = useState<TreeNodeList>([])
	const [iterator, setIterator] = useState(new TreeIterator<TreeNode>([]))

	const expandedKey = localStorageKey ? localStorageKey + '.expanded' : undefined
	const selectedKey = localStorageKey ? localStorageKey + '.selected' : undefined
	const [expanded, setExpanded] = useLocalStorageOrState<string[]>(expandedKey, [])
	const [selected, setSelected] = useLocalStorageOrState<string | string[]>(selectedKey, [])

	let sxProps: any = { ...sx }
	if (fill) sxProps = { display: 'inlink-block', width: '100%', ...sxProps }

	if (fontSize) sxProps = { ...sxProps, '& .MuiTreeItem-label': { fontSize: fontSize } }

	useEffect(() => {
		if (!data) return
		let nodes: TreeNodeList = []
		if (!Array.isArray(data)) nodes = [new TreeNode(data)]
		else nodes = data.map((child: ITreeNodeData, idx: number) => new TreeNode(child))
		setIterator(new TreeIterator(nodes))
		setItems(nodes)
	}, [data])

	const renderChildren = () => {
		if (!items?.length) return null
		return items.map((item, idx) => <DataTreeItem key={idx} item={item} />)
	}

	const onNodeToggle = (e: any, nodeIds: string[]) => {
		setExpanded(nodeIds)
	}
	const idComparer = (targetId: string) => {
		return (item: TreeNode) => targetId === item.id()
	}
	const getItemById = async (nodeIds: string[] | string) => {
		let id: string
		if (Array.isArray(nodeIds) && nodeIds.length > 0) id = nodeIds[0]
		else id = nodeIds as string
		if (!nodeIds?.length) return undefined
		return await iterator.findNode(idComparer(id))
	}

	const onNodeSelect = async (e: any, nodeIds: string[] | string) => {
		if (!equals(nodeIds, selected)) setSelected(nodeIds)

		const item = await getItemById(nodeIds)
		if (item && onTreeNodeClick) {
			const src = getValueFromFunction(item.source)
			if (src) onTreeNodeClick(getValueFromFunction(src?.data), src)
		}
	}
	const doSetExpanded = (id: string, isExpanded: boolean) => {
		const idx = expanded.indexOf(id)
		if (isExpanded && idx < 0) setExpanded([...expanded, id])
		else if (!isExpanded && idx >= 0) {
			const exp = [...expanded]
			exp.splice(idx, 1)
			setExpanded(exp)
		}
	}
	const doGetExpanded = (id: string): boolean => {
		const idx = expanded.indexOf(id)
		return idx >= 0
	}
	const context = { restrictExpansionToIcon, setExpanded: doSetExpanded, getExpanded: doGetExpanded }
	return (
		<DataTreeContext.Provider value={context}>
			{/* @ts-ignore */}
			<TreeView
				defaultCollapseIcon={<ExpandMore style={{ fontSize }} />}
				defaultExpandIcon={<ChevronRight style={{ fontSize }} />}
				sx={sxProps}
				onNodeToggle={onNodeToggle}
				expanded={expanded}
				onNodeSelect={onNodeSelect}
				selected={clientSelected || selected}
				{...rest}
			>
				{renderChildren()}
			</TreeView>
		</DataTreeContext.Provider>
	)
})

// NOTE: No point in doing this as this only allows you to override the root style as that is all we are specifying here.
// Any other slots, such as group, etc. are still called MuiTreeItem-group
// const DataTreeViewRoot = styled(TreeView, {
// 	name: 'DataTreeView',
// 	slot: 'Root',
// 	overridesResolver: (props: any, styles: any) => [styles.root],
// })(({ theme }) => ({}))
// const DataTreeItemRoot = styled(TreeItem, {
// 	name: 'DataTreeItem',
// 	slot: 'Root',
// 	overridesResolver: (props: any, styles: any) => [styles.root],
// })(({ theme }) => ({}))
