import { MouseTouchEvent, useComponentSize, useContextMenu, useCurrentRef, useLocalStorageOrState } from '@app/hooks'
import { getPixelSize, getTextSizeForElement, getXLongestStrings, logRenderReason, Point, SortField, transpose } from '@app/utils'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import { useThemeProps } from '@mui/material'
import anylogger from '@app/anylogger'
import clsx from 'clsx'
import React, {
	createRef,
	forwardRef,
	KeyboardEvent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import { VariableSizeGrid } from 'react-window'
import { DraggableFlex } from './DraggableFlex'
import { Flex, FlexC } from './Flex'
import { SelectMode, useSelectionController } from './hooks/useSelectionController'
import { IListViewProvider } from './IListViewProvider'
import { ListViewAdapter } from './ListViewAdapter'
import { Buttons, Save, Yes } from './ModalDialog'
import { useModal } from './ModalOverlay'
import { usePopupMenu } from './PopupMenuOverlay'
import { Text } from './Text'
import { TitleDefinition, TitleDefinitionList } from './TitleDefinition'
import TitleDefinitionListView from './TitleDefinitionListView'
import {
	getUtilityClasses,
	VirtualListStickyHeader,
	VirtualListViewCell,
	VirtualListViewRoot,
	VirtualListViewTitleCell,
	VirtualListViewTitleRow
} from './VirtualListViewStyles'

export const log = anylogger('VirtualListView')

const StickyContext = React.createContext<any>(undefined)

/**
 * Bug: if the titles prop passed in is not memoized, it causes the list to be refreshed, and the focused and selected persistence does not work.
 * * Perhaps we could not base the adapter data change on the the titles, but they are used to calculate the column sizes
 *
 * Performance:
 * * setHoverRow re-renders the entire grid, including titles
 */
export interface SelectedItem<T = any> {
	item: T
	index: number
	id: any
}
interface VirtualListViewProps {
	/**
	 * Provides the row data and title configuration
	 */
	provider: IListViewProvider

	// /**
	//  * This provides the default set of titles to be displayed, which can be a subset of allTitles (specified in the provider)
	//  * If titles is specified, this will NOT be used. This is meant to be a default list of titles, which the user can override
	//  * via the "Select Columns" menu option, which is available from the title bar context menu.
	//  */
	defaultTitles?: TitleDefinitionList

	/**
	 * Provides the titles to be displayed, which can be a subset of the allTitles specified in the provider.
	 * The allows you to specify user-specified titles from the client (by listening
	 * to the userSelectedTitlesChanged event and returning those values through state).  Or to allow this VirtualListView
	 * component to control the user-specified columns by storing them in local storage via the specified localStorageKey prop.
	 * NOTE: This should be memoized, because if it changes every render, then the focus and selection persistence will not work.
	 */
	titles?: TitleDefinitionList

	/**
	 * This is called when the user selects the columns that they want to display.
	 * The client can save the columns in state or local storage, and then pass those same columns to the VirtualListView
	 * to control which columns are displayed
	 */
	userSelectedTitles?: (titles: TitleDefinitionList) => void

	/**
	 * The gap between the columns.  Defaults to 1em.  If not large enough, the title sort indicator may be clipped
	 * if the width of the title is greater than the width of the data
	 */
	gap?: string

	/**
	 * If true, keeps the titles visible when scrolling.  Default: true
	 * If this is turned off, column dragging/resizing will be disabled.
	 */
	stickyHeader?: boolean

	/**
	 * The height of each row: Default: 1.5em
	 */
	rowHeight?: string

	/**
	 * If specified, this is the base localStorageKey where the Sort Index and sort Ascending will be stored between sessions
	 */
	localStorageKey?: string

	/**
	 * The default sort field(s)
	 */
	defaultSort?: SortField | SortField[]
	/**
	 * If specified, then the ListView will show hover effects for each row
	 */
	hover?: boolean

	/**
	 * If specified, then the ListView will show the currently focused row
	 */
	focus?: boolean
	/**
	 * If true, then every second row will be shaded for easier visibility
	 */
	stripe?: boolean

	/**
	 * if specified, a separator will be drawn between the lines
	 */
	separator?: boolean

	/**
	 * Controls row selection. 'single', 'multi' or 'none'.  Defaults to 'single;
	 */
	selectMode?: SelectMode

	/**
	 * Allows filtering the items in the list
	 */
	filterText?: string

	/**
	 * the delay in milliseconds after the last change in filterText after which the filter is performed
	 * Default: 500ms
	 */
	filterDelay?: number

	/**
	 * Prevents the user from selecting text (which can provide for a cleaner row-selection experience).
	 * Defaults to true if selectMode != 'none
	 */
	preventTextSelection?: boolean

	/**
	 * Called when the user clicks an item with the mouse
	 */
	itemClicked?: (clickedItem: SelectedItem) => void

	/**
	 * Called when the user double-clicks an item with the mouse
	 */
	itemDoubleClicked?: (clickedItem: SelectedItem) => void

	/**
	 * Called when an item is focused, whether clicking with a mouse, or using the keyboard navigation keys (Up, Down, Home, PageDown, etc)
	 */
	itemFocused?: (focused: SelectedItem) => void

	/**
	 * Called when there is a keydown event on the whole list view
	 */
	onKeyDown?: (e: KeyboardEvent) => boolean | void
	/**
	 * Called whenever the number of selected items changes. Standard key and mouse combinations change selection:
	 * i.e. Ctrl-Click, Shift-Click, Shift-Up/Down, Ctrl-Shift-Home/End, Shift-PageUp/Down
	 */
	selectionChanged?: (selected: SelectedItem[]) => void

	/**
	 * Called when the right mouse button is clicked.  It can return an array of ReactElements that will be displayed in a popup menu.
	 */
	onGetContextMenuItems?: (focused: SelectedItem, selected: SelectedItem[]) => ReactElement[]

	// Not sure we need this unless we decide to change the list view to have variable sized columns
	// preveventCellWordWrap?: boolean
	// We also don't need this if we don't have variable row sizes as we will always center the text vertically
	// verticalAlignment?: Property.VerticalAlign
}
const VirtualListView = React.forwardRef(function VirtualListView(inProps: VirtualListViewProps, passedRef: any) {
	const props = useThemeProps({ props: inProps, name: 'VirtualListView' })
	const {
		provider,
		defaultTitles,
		titles: propTitles,
		gap = '1em',
		rowHeight: rowHeightProp = '1.5em',
		stickyHeader = true,
		localStorageKey,
		defaultSort = [],
		hover,
		focus,
		stripe,
		selectMode = 'single',
		itemClicked,
		itemDoubleClicked,
		itemFocused,
		onKeyDown,
		selectionChanged,
		onGetContextMenuItems,
		separator,
		preventTextSelection = selectMode != 'none',
		filterText = '',
		filterDelay = 500,
		userSelectedTitles
	} = props

	const [refreshCount, setRefreshCount] = useState(0)
	const refresh = useCallback(() => {
		setRefreshCount((prev) => prev + 1)
	}, [])
	const [adapter, setAdapter] = useState<ListViewAdapter>(new ListViewAdapter(refresh))
	const [colSizes, setColSizes] = useState<number[]>([])
	const [rowHeight, setRowHeight] = useState(0)
	const [gapPx, setGapPx] = useState(0)
	const [sortFields, setSortFields] = useLocalStorageOrState<SortField[]>(
		createKey('sortFields', localStorageKey),
		Array.isArray(defaultSort) ? defaultSort : [defaultSort]
	)
	// const [sortAsc, setSortAsc] = useLocalStorageOrState(createKey('sortAsc', localStorageKey), '')
	const [userTitles, setUserTitles] = useLocalStorageOrState<TitleDefinitionList>(createKey('userTitles', localStorageKey), [])

	const [hoverRow, setHoverRow] = useState(-1)
	const [focused, setFocused] = useState<any>()
	const [selected, setSelected] = useState<any[]>([])

	const [flexRef, setFlexRef] = useCurrentRef<HTMLElement>(passedRef)
	const [gridRef, setGridRef] = useCurrentRef<VariableSizeGrid>()
	const [sampleCell, setSampleCell] = useCurrentRef<HTMLElement>()
	const [sampleTitle, setSampleTitle] = useCurrentRef<HTMLElement>()
	const size = useComponentSize(flexRef)

	// this is pretty static and 'should not' change after the original render (unless the props change)
	useEffect(() => {
		if (!flexRef || !gridRef) return
		// log('GAP OR ROWHEIGHT changed')

		setGapPx(getPixelSize(flexRef, gap))
		setRowHeight(getPixelSize(flexRef, rowHeightProp))
	}, [flexRef, gap, gridRef, rowHeightProp])

	const calculateColumnSizes = useCallback(
		(rawData: any[][]) => {
			if (!adapter || !flexRef || !sampleCell || !sampleTitle || !gridRef) return
			const sizes = getColumnSizes(rawData, flexRef, adapter, sampleCell, sampleTitle)

			setColSizes(sizes)
			gridRef.resetAfterColumnIndex(0, false) // false means wait until the next render so that the state changes have time to take effect
		},
		[adapter, flexRef, sampleCell, sampleTitle, gridRef]
	)
	const buildSelectedItem = useCallback(
		(item: any) => {
			return { item: item.item, index: adapter.getIndexOfId(item.id), id: item.id }
		},
		[adapter]
	)
	const buildSelectedItems = useCallback(
		(sel: any[]) => {
			return sel.map((item) => {
				return buildSelectedItem(item)
			})
		},
		[buildSelectedItem]
	)

	// provider changes
	useEffect(() => {
		if (!calculateColumnSizes || !adapter || !gridRef) return
		// log('DATA changed', provider)
		const titles = (propTitles?.length ? propTitles : userTitles.length ? userTitles : undefined) ?? defaultTitles ?? []

		adapter.setProvider(provider, titles, calculateColumnSizes)
	}, [adapter, provider, propTitles, calculateColumnSizes, gridRef, userTitles, defaultTitles])

	// instead of acting on the selection changes in the event handlers, we wait for the state to be changed
	// by the selection handler and then send send the notification
	useEffect(() => {
		if (!selectionChanged) return
		const sel = buildSelectedItems(selected)
		selectionChanged(sel)
	}, [adapter, buildSelectedItems, selected, selectionChanged])

	// when the focused item changes, send out notifications and scroll to ensure it is in view
	useEffect(() => {
		if (!focused || !gridRef) return
		const index = adapter.getIndexOfId(focused.id)
		if (itemFocused) itemFocused(buildSelectedItem(focused))
		gridRef?.scrollToItem({ align: 'smart', rowIndex: index })
	}, [adapter, buildSelectedItem, focused, gridRef, itemFocused])

	// when the sortFields change, resort
	useEffect(() => {
		if (!adapter) return

		adapter.setSort(sortFields)
		if (focused && gridRef) {
			const row = adapter.getIndexOfId(focused.id)
			gridRef?.scrollToItem({ align: 'smart', rowIndex: row })
		}
		// if we have  a focused row, we want to restore it, but we do not want to resort if it changes, so we are omitting focused from the dependencies
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adapter, sortFields, gridRef])

	// when the filterText changes, refilter
	useEffect(() => {
		if (!adapter) return
		adapter.setFilterText(filterText, filterDelay)
	}, [adapter, filterText, filterDelay])

	const { createMenuItem, showPopup, closePopup: closeContextMenu } = usePopupMenu()
	const { customDialog, yesNoPrompt } = useModal()

	const doUserTitles = useCallback(
		(titles: any[]) => {
			setUserTitles(titles)
			// we have to copy the titles to a new array before the callback so that if the client
			// mutates the list it will not affect us because the adapter now maintains and returns
			// the same list that is originally used to prevent re-renders
			if (userSelectedTitles) userSelectedTitles([...titles])
		},
		[setUserTitles, userSelectedTitles]
	)
	const selectColumns = useCallback(async () => {
		const res = await customDialog({
			title: 'Select the columns you want',
			dialog: <TitleDefinitionListView allTitles={[...adapter.allTitles]} selectedTitles={[...adapter.titles]} />,
			buttons: [Buttons.Save, Buttons.Discard]
		})
		log('res', res)

		if (res.result == Save) {
			doUserTitles(res.data)
		}
	}, [adapter.allTitles, adapter.titles, customDialog, doUserTitles])
	const resetColumns = useCallback(() => {
		yesNoPrompt({ title: 'Are you sure?', content: 'Do you want to reset the columns to their default values?' }).then((res) => {
			if (res == Yes) setUserTitles([])
		})
	}, [setUserTitles, yesNoPrompt])
	const getRowFromPoint = useCallback((p: Point) => {
		if (!document) return -1
		const el = document.elementFromPoint(p.x, p.y)
		if (!el) return -1
		const idx = el.getAttribute('rowindex')
		if (!idx) return -1
		return Number(idx)
	}, [])
	const showContextMenu = useCallback(
		(e: any) => {
			let menuItems: any[] = []
			if (onGetContextMenuItems) {
				const p = new Point(e.clientX, e.clientY)
				const row = getRowFromPoint(p)
				let sel = selected

				if (row >= 0) {
					const item = adapter.getItemByIndex(row)
					if (item) {
						setFocused(item)
						if (!selected.includes(item)) {
							sel = [item]
							setSelected(sel)
						}
						menuItems = onGetContextMenuItems(buildSelectedItem(item), buildSelectedItems(sel))
					}
				}
			}
			showPopup(
				[
					...menuItems,
					createMenuItem('Select Columns', () => selectColumns()),
					createMenuItem('Reset Columns to Default', () => resetColumns())
				],
				flexRef
			)
		},
		[
			adapter,
			buildSelectedItem,
			buildSelectedItems,
			createMenuItem,
			flexRef,
			getRowFromPoint,
			onGetContextMenuItems,
			resetColumns,
			selectColumns,
			selected,
			showPopup
		]
	)

	const { selItemClick, selKeyDown } = useSelectionController({
		list: adapter.items,
		selectMode,
		focused,
		selected,
		setFocused,
		setSelected
	})
	const keyDown = useCallback(
		(e: any) => {
			if (onKeyDown) onKeyDown(e)
			selKeyDown(e)
		},
		[onKeyDown, selKeyDown]
	)

	const titleClick = useCallback(
		(titleDef: TitleDefinition, e: MouseTouchEvent) => {
			if (!adapter.titles?.length) return
			const ev = e.isTouch ? e.touchEvent! : e.mouseEvent!
			ev.preventDefault()
			ev.stopPropagation()

			setSortFields((prev) => {
				let res = [...prev]

				let srt = res.find((fld) => fld.field == titleDef.name)
				if (srt) srt.isDesc = !srt.isDesc
				else {
					srt = { field: titleDef.name, isDesc: false, isNumeric: titleDef.isNumeric }
					// we can only use Ctrl ifit is a mouse event
					if (e.mouseEvent?.ctrlKey) {
						res.push(srt)
					} else {
						res = [srt]
					}
				}

				return res
			})
		},
		[adapter, setSortFields]
	)

	const itemClick = useCallback(
		(item: any, e: any) => {
			selItemClick(item, e)
			if (itemClicked) itemClicked(buildSelectedItem(item))
		},
		[buildSelectedItem, itemClicked, selItemClick]
	)
	const doubleClick = useCallback(
		(item: any, e: any) => {
			log('item', item)

			selItemClick(item, e)
			if (itemDoubleClicked) itemDoubleClicked(buildSelectedItem(item))
		},
		[buildSelectedItem, itemDoubleClicked, selItemClick]
	)

	const getColSize = useCallback(
		(idx: number) => {
			// if (idx == 0) log('Titles', colSizes)
			// else log('Cell', colSizes)

			let res = idx < colSizes.length ? colSizes[idx] : 100
			// log('res', res)

			res = res + gapPx
			return res
		},
		[colSizes, gapPx]
	)
	const getRowHeight = useCallback(
		(idx: number) => {
			return rowHeight
		},
		[rowHeight]
	)

	// if (!adapter.rowCount && !adapter.colCount) return <Text>No Data</Text>
	// else if (!items?.length) return <Text>Loading...</Text>

	const rootState = useMemo(() => {
		return {
			preventTextSelection
		}
	}, [preventTextSelection])

	const ctx = {
		adapter,
		titles: adapter.titles,
		gap,
		gapPx,
		colSizes,
		stickyHeader,
		getColSize,
		rowHeight,
		sampleTitle,
		titleClick,
		sortFields,
		hover,
		hoverRow,
		setHoverRow,
		focus,
		focused,
		stripe,
		setFocused,
		itemClick,
		doubleClick,
		selectMode,
		selected,
		selKeyDown,
		separator,
		preventTextSelection,
		doUserTitles,
		showContextMenu,
		closeContextMenu
	}
	return (
		<StickyContext.Provider value={ctx}>
			{/* @ts-ignore */}
			<VirtualListViewRoot fill onKeyDown={keyDown} ownerState={rootState} tabIndex={0}>
				{/* Unfortunately, we can't use this because in mobile(or with a horizontal scroll bar),
				the titles need to scroll with the data
				So, we have to draw all the titles within the grid, even if stickyHeader
				{stickyHeader && <Titles />}  */}
				<FlexC ref={setFlexRef} fill scroll>
					<VirtualListViewCell
						ref={setSampleCell}
						// @ts-ignore
						ownerState={{}}
						visibility="hidden"
						position="fixed"
					></VirtualListViewCell>
					<VirtualListViewTitleCell
						ref={setSampleTitle}
						// @ts-ignore
						ownerState={{}}
						visibility="hidden"
						position="fixed"
					></VirtualListViewTitleCell>
					{/* @ts-ignore */}
					<VariableSizeGrid
						ref={setGridRef}
						// @ts-ignore
						innerElementType={StickyHeader}
						rowCount={(adapter.titles.length ? 1 : 0) + adapter.rowCount}
						columnCount={adapter.colCount}
						columnWidth={getColSize}
						rowHeight={getRowHeight}
						// The size is rounded to an integer, so may be slightly larger than the actual size, causing a scroll bar.
						// Subtracting a pixel solves the problem.
						height={Math.floor(size.height - 1)}
						width={Math.floor(size.width - 1)}
					>
						{/* @ts-ignore */}
						{CellWrapper}
					</VariableSizeGrid>
				</FlexC>
			</VirtualListViewRoot>
		</StickyContext.Provider>
	)
})

// we have to use this convoluted StickyHeader as an inner element because
// if we just change the header cells to be sticky, then they are not absolute, and are not displayed in the proper place
const StickyHeader = memo(function StickyHeader(props: any) {
	let { children, ...rest } = props
	const { showContextMenu } = useContext(StickyContext)

	const [ref, setRef] = useCurrentRef<HTMLElement>()

	useContextMenu(ref, showContextMenu)

	return (
		<VirtualListStickyHeader {...rest}>
			<Titles />
			<Flex ref={setRef} fill>
				{props.children}
			</Flex>
		</VirtualListStickyHeader>
	)
})

const Titles = memo(function Titles(props: any) {
	const {
		titles,
		titleClick,
		doUserTitles,
		stickyHeader,
		getColSize,
		rowHeight,
		preventTextSelection,
		gapPx,
		showContextMenu,
		closeContextMenu
	} = useContext(StickyContext)

	const [dragging, setDragging] = useState(false)
	const [resizing, setResizing] = useState(false)

	const [ref, setRef] = useCurrentRef<any>()

	const internalShowContextMenu = useCallback(
		(...params: any) => {
			log('internalShowContextMenu')

			if (!dragging && !resizing) showContextMenu(...params)
		},
		[dragging, resizing, showContextMenu]
	)

	useContextMenu(ref, internalShowContextMenu)

	const onDragging = useCallback(
		(isDragging: boolean) => {
			setDragging(isDragging)
			if (isDragging) closeContextMenu()
		},
		[closeContextMenu]
	)
	const onResizing = useCallback(
		(isResizing: boolean) => {
			setResizing(isResizing)
			if (isResizing) closeContextMenu()
		},
		[closeContextMenu]
	)
	const titlesChanged = useCallback(
		(oldIdx: number, newIdx: number) => {
			// clone the titles so that we don't mutate the original reference
			const res = [...titles]

			res.splice(newIdx, 0, ...res.splice(oldIdx, 1))

			doUserTitles(res)
		},
		[doUserTitles, titles]
	)
	const onResized = useCallback(
		(idx: number, newSize: number | undefined) => {
			let newTitles = [...titles]
			newTitles[idx] = { ...newTitles[idx] }
			let td = newTitles[idx]
			if (typeof newSize == 'undefined') {
				delete td.width
			} else {
				if (newSize < 10) newSize = 10
				newSize = newSize - gapPx
				td.width = newSize
			}
			log('newTitles[idx]', newTitles[idx])

			doUserTitles(newTitles)
		},
		[doUserTitles, gapPx, titles]
	)

	const onClick = useCallback(
		(idx: number, e: MouseTouchEvent) => {
			titleClick(titles[idx], e)
		},
		[titleClick, titles]
	)

	const getTitles = useMemo(() => {
		let left = 0
		return titles.map((title: any, idx: number) => {
			const width = getColSize(idx)
			const res = (
				<TitleCell key={idx} columnIndex={idx} style={{ position: 'absolute', left, width, height: rowHeight }}>
					{title.name}
				</TitleCell>
			)
			left = left + width
			return res
		})
	}, [getColSize, rowHeight, titles])

	if (!stickyHeader || !titles?.length) return null

	return (
		<VirtualListViewTitleRow ref={setRef} height={rowHeight}>
			<DraggableFlex
				axis="x"
				onItemClick={onClick}
				onDragged={titlesChanged}
				allowResizing={true}
				onResized={onResized}
				onDragging={onDragging}
				onResizing={onResizing}
			>
				{getTitles}
			</DraggableFlex>
		</VirtualListViewTitleRow>
	)
})
const TitleCell = React.forwardRef(function TitleCell(props: any, ref: any) {
	const { columnIndex, style, children } = props
	const { gap, titleClick, titles, sortFields, stickyHeader } = useContext(StickyContext)
	const [isDown, setIsDown] = useState(false)
	const title = titles[columnIndex]

	const mouseDown = (e: any) => {
		if (e.button != 0 || !stickyHeader) return
		setIsDown(true)
	}
	const mouseUp = (e: any) => {
		setIsDown(false)
	}
	const mouseLeave = (e: any) => {
		setIsDown(false)
	}

	const sortField: SortField = sortFields.find((srt: SortField) => srt.field == titles[columnIndex].name)

	const alignment = title?.titleAlignment || title.alignment
	const state = useMemo(() => {
		return {
			isDown,
			rightAligned: alignment ? ['right', 'end'].includes(alignment) : undefined,
			centered: alignment == 'center'
		}
	}, [alignment, isDown])
	const classes = getUtilityClasses(state)

	// !NOTE:  Do not use sx here because it creates A new CSS style for every component instance, drastically slowing down the refresh
	return (
		<VirtualListViewTitleCell
			ref={ref}
			className={clsx(classes.title)}
			style={style}
			onMouseDown={mouseDown}
			onMouseUp={mouseUp}
			onMouseLeave={mouseLeave}
			// @ts-ignore
			ownerState={state}
		>
			{children}
			{stickyHeader && <SortImage size={gap} sorted={sortField} isAsc={!sortField || !sortField.isDesc} />}
		</VirtualListViewTitleCell>
	)
})

const CellWrapper = memo(function CellWrapper(props: any) {
	let { columnIndex, rowIndex } = props
	const { adapter, titles, colSizes, stickyHeader } = useContext(StickyContext)
	const title = titles?.length ? titles[columnIndex] : undefined

	if (rowIndex == 0 && title) {
		if (stickyHeader) return null

		const val = title.name
		return <TitleCell {...props}>{val}</TitleCell>
	}

	if (title) rowIndex = rowIndex - 1

	const val = adapter.getDisplayValue(rowIndex, columnIndex)

	// !NOTE:  Do not use sx here because it creates A new CSS style for every component instance, drastically slowing down the refresh
	return (
		<Cell {...props} rowIndex={rowIndex}>
			{val}
		</Cell>
	)
})
const Cell = memo(function Cell(props: any) {
	const { rowIndex, columnIndex, style, rowHeight, children } = props
	const {
		adapter,
		hover,
		hoverRow,
		setHoverRow,
		focus,
		focused,
		selectMode,
		selected,
		itemClick,
		doubleClick,
		separator,
		stripe,
		titles
	} = useContext(StickyContext)
	const title = titles?.length ? titles[columnIndex] : undefined

	const item = adapter.getItemByIndex(rowIndex)

	const mouseEnter = useCallback(() => {
		setHoverRow(rowIndex)
	}, [rowIndex, setHoverRow])
	const mouseLeave = useCallback(() => {
		setHoverRow(-1)
	}, [setHoverRow])
	const click = useCallback(
		(e: any) => {
			itemClick(item, e)
		},
		[item, itemClick]
	)
	const dblClick = useCallback(
		(e: any) => {
			log('item', item)

			doubleClick(item, e)
		},
		[item, doubleClick]
	)
	const state = useMemo(() => {
		return {
			hover,
			isHovered: hoverRow == rowIndex,
			focus,
			isFocused: focused == item,
			select: selectMode != 'none',
			isSelected: selected.includes(item),
			stripe: stripe && rowIndex % 2 == 1,
			separator: separator && rowIndex < adapter.rowCount - 1,
			rightAligned: title?.alignment ? ['right', 'end'].includes(title.alignment) : undefined,
			centered: title?.alignment == 'center'
		}
	}, [adapter.rowCount, focus, focused, hover, hoverRow, item, rowIndex, selectMode, selected, separator, stripe, title.alignment])

	// this generates class names, based on the state, which are passed to the style component
	const classes = getUtilityClasses(state)

	// !NOTE:  Do not use sx here because it creates A new CSS style for every component instance, drastically slowing down the refresh
	return (
		<VirtualListViewCell
			className={clsx(classes.cell)}
			style={style}
			onMouseEnter={mouseEnter}
			onMouseLeave={mouseLeave}
			onClick={click}
			onDoubleClick={dblClick}
			// must be lowercase or React will complain about passing it to the underlying div
			// and this is how we get the element at an x,y position
			// @ts-ignore
			rowindex={rowIndex}
			// @ts-ignore
			columnindex={columnIndex}
			// we also pass the ownerState to the style component so that it can tweak it's style based on that state
			// @ts-ignore
			ownerState={state}
		>
			{children}
		</VirtualListViewCell>
	)
})
const SortImage = (props: any) => {
	const { sorted, isAsc } = props
	const { gap } = useContext(StickyContext)

	if (!sorted) return <Text width={gap} />

	return isAsc ? (
		<ArrowDropUp
			sx={{
				fontSize: gap
			}}
		/>
	) : (
		<ArrowDropDown
			sx={{
				fontSize: gap
			}}
		/>
	)
}

function getColumnSizes(
	rawData: any[][],
	flexRef: HTMLElement,
	adapter: ListViewAdapter,
	sampleCell: HTMLElement,
	sampleTitle: HTMLElement
) {
	// We want to calculate the maximum width of each column, by measuring the textWidth of each element.
	// getTextWidth (which uses canvas.context.width is quick, but falls a little short ;) i.e. it is not accurate in that it is shorter than the actual text)
	// getDivWidth which creates a visiblity:hidden div and then measures the width after setting the text is a a lot slower
	// and can take 250-350ms for 300 items on a fast machine, which is not accepable.
	// To speed this up, we can find the 5 or 10 longest items in each column and then do a getDivWidth on those.
	// The reason we want more than just the longest is because words with lots of i's and t's will be a lot smaller,
	// The new time to calculate the col size for 300 rows is about 10-24 ms

	// To get the top 5 or 10 items, we first need to transpose the rows of columns structure to a column of rows

	const data = rawData

	// have to deal with the situation where the items in data might be objects instead of an array

	// if (Array.isArray(provider.items))
	// else if (typeof provider.items == 'object') data = Object(provider.items).values.map((item: any) => Object.values(item.obj))
	// else throw new Error(`Unsupported data: ${provider.items}`)
	const cols = transpose(data)

	// converty any objects to the value (not displayValue)
	const colStrs = cols.map((col) =>
		col.map((cell) =>
			cell?.displayValue && typeof cell.displayValue == 'string' ? cell.displayValue : cell?.value ? cell.value : cell
		)
	)
	// next we need to get the X longest items
	const longest = colStrs.map((col) => getXLongestStrings(col, 5))

	// longest now contains 1 row for each column, each containing the longest x entries of that column
	let allCols: number[] = longest.map((colArr) => {
		return colArr.reduce((res, item) => {
			const r = getTextSizeForElement(sampleCell, item)
			const w = r.width
			return w > res ? w : res
		}, 50)
	})

	let res: number[] = []
	// it is probably not necessary to make a copy of allTitles and titles, but we have to make sure that we do not corrupt the
	// original values from adapter because it returns the actual list (not a copy) to prevent excess rendering
	const allTitles = [...adapter.allTitles]
	const titles = [...adapter.titles]
	if (titles.length && !allTitles.length)
		log.error('If specifying titles in the VirtualListView, you must specify allTitles in the provider')
	if (titles?.length) {
		// we calculated the sizes for all of the columns, but now we have to return colSizes for just the columns
		// that are being displayed, and in the proper order.
		// so we iterate through the titles, find them in the all titles
		for (let ii = 0; ii < titles.length; ii++) {
			const title = titles[ii]
			const matching = allTitles.find((t) => t.name == title.name)
			if (matching) {
				const idx = allTitles.indexOf(matching)
				if (idx >= 0) res.push(allCols[idx])
			}
		}
	} else res = allCols

	if (titles) {
		for (let idx = 0; idx < titles.length; idx++) {
			const title = titles[idx]
			if (res.length < idx + 1) res.push(0)
			if (title.width) res[idx] = getPixelSize(flexRef, title.width)
			else {
				const r = getTextSizeForElement(sampleTitle, title.name)
				const w = r.width
				if (typeof res[idx] == 'undefined' || w > res[idx]) res[idx] = w
			}
		}
	}

	return res
}
function createKey(key: string, localStorageKey?: string): string | undefined {
	return localStorageKey ? localStorageKey + '.' + key : undefined
}
const vm = React.memo(VirtualListView)
export { vm as VirtualListView }
