import anylogger from '@app/anylogger'
import { NestedError } from './NestedError'
import { isBase64, looksLikeJson } from '@app/utils'
import { NextApiResponse } from 'next'
import { Readable } from 'stream'
const log = anylogger('serverProxy')

export type ParamsCallback = (params: any) => any

export function stringifyError(err: any): string {
	let props = Object.getOwnPropertyNames(err)
	return typeof err == 'object' ? JSON.stringify(err, props) : err
}

export function sendHttpError(res: NextApiResponse, status: number, err: any) {
	console.error(err)
	res.status(status).send(stringifyError(err))
}

interface Options {
	paramsCallback?: ParamsCallback
	cacheDuration?: number
}
export class ServerProxy {
	apiRoot: string
	api: any
	constructor(apiRoot: string, api: any) {
		this.apiRoot = apiRoot
		this.api = api
	}
	async execute(req: any, res: NextApiResponse, options: Options = {}) {
		let meth: string = ''
		let str = ''
		try {
			str = await req.body
			let params: any = []
			if (str) params = JSON.parse(str)

			meth = this.extractApiCall(req.url)
			// log('meth', meth)

			let logParams = undefined
			if (process.env.NODE_ENV == 'development' && params) {
				logParams = params.map((val: any) => {
					let res = val
					if (typeof val == 'string') {
						res = val.slice(0, 50)
						if (val.length > 50) res += '...'
					}
					return res
				}, [])
			}

			log(req.url, logParams)

			// Provide a params callback so that the calling method can inject any session-specific parameters
			if (options.paramsCallback) params = options.paramsCallback(params)

			// this could be used to alleviate casing issues, to find the exactly named function
			// const props = Object.keys(this.api).filter((item)=> item.toLocaleLowerCase() === meth.toLocaleLowerCase())

			if (options.cacheDuration) {
				res.setHeader('Cache-Control', `public, max-age=${options.cacheDuration}`)
			}

			const apiResponse = this.api[meth](...params)
			// if the api does not return a promise, then just return the result
			if (!apiResponse?.then) {
				return res.status(200).send(apiResponse)
			}

			await apiResponse.then(async (results: any) => {
				// log('got result', results)
				if (results instanceof Response) {
					// if the api result is a Response object, pipe the headers and the body to res.
					// Also add an "IsRawResponse" header so that simpleFetch (which normally returns text or json)
					// knows to return the entire response to the api client as well
					results.headers.forEach((value, key) => {
						res.setHeader(key, value)
					})
					res.setHeader('IsRawResponse', 'true')
					const readable = Readable.fromWeb(results.body as any)
					readable.pipe(res)
				} else if (isBase64(results)) {
					return res.status(200).send(results)
					// this doesn't work because if it looks like json (i.e. it is in text form) then there is no need to use ....json(results).  We can just return the text
					// } else if (looksLikeJson(results)) {
				} else if (typeof results == 'object') {
					return res.status(200).json(results)
				} else {
					return res.status(200).send(results)
				}
			})
		} catch (err: any) {
			let status = err.status ?? 500
			const mess = `Error executing server api: ${meth}`
			const newErr = new NestedError(mess, err)
			console.error(err)
			sendHttpError(res, status, newErr)
		}
	}
	extractApiCall(rawUrl: any): string {
		const urlObj = new URL(rawUrl, 'http://test') // need to provide a fake base path.  It will only be used if the url param does not have one.
		const url = urlObj.pathname
		return url.slice(this.apiRoot.length + 1)
	}
	async writeStream(strm: ReadableStream<Uint8Array>, res: any) {
		await new Promise<void>(async (resolve, reject) => {
			const reader = strm.getReader()
			return new ReadableStream({
				async start(controller) {
					while (true) {
						const { done, value } = await reader.read()

						// When no more data needs to be consumed, break the reading
						if (done) {
							break
						}
						res.write(value)
					}

					// Close the stream
					controller.close()
					reader.releaseLock()
					res.end()
					resolve()
				}
			})
		})
	}
	async createStream(strm: ReadableStream<Uint8Array>) {
		const reader = await strm.getReader()
		return new ReadableStream({
			async start(controller) {
				while (true) {
					const { done, value } = await reader.read()

					// When no more data needs to be consumed, break the reading
					if (done) {
						break
					}
					controller.enqueue(value)
				}

				// Close the stream
				controller.close()
				reader.releaseLock()
			}
		})
	}
}
