import { PaletteMode, alpha, createTheme } from '@mui/material'
import { border } from '@mui/system'

// Create the base color theme
function createBaseTheme(mode: PaletteMode) {
	const theme = createTheme({
		typography: {
			button: { textTransform: 'none' },
			fontSize: 16,
			// we need to set this to 1rem because this is used to set the font size of the body element
			body1: { fontSize: '1rem' }
			// body1: {
			// 	'@media (min-width:600px)': {
			// 		fontSize: '1.5rem'
			// 	}
			// }
		},

		breakpoints: {
			values: {
				mobile: 0,
				tablet: 640,
				laptop: 1024,
				desktop: 1200
			}
		},
		palette: {
			mode,
			primary: {
				// light: '#8e99f3',
				main: '#5c6bc0'
				// dark: '#26418f',
				// contrastText: '#fff',
			},
			secondary: {
				// light: '#ffe97d',
				main: '#ffb74d'
				// dark: '#c88719d',
				// contrastText: '#000',
			},
			contrastThreshold: 3,
			tonalOffset: 0.2,
			action: {
				// selectedOpacity: mode == 'light' ? 0.2 : 0.3,
				focusOpacity: 0.4,
				selectedOpacity: 0.25,
				hoverOpacity: 0.1
			}
		}
		// spacing: [0, 4, 8, 12, 16],
	})
	return theme
}

// alter the theme to assign any theme colors (or other preset values) to components
export default function getTheme(mode: PaletteMode) {
	let t = createBaseTheme(mode)

	// This does not seem to work as I would like, so I implemented adjustFontSizes in _app
	// t = responsiveFontSizes(t, { factor: 4, breakpoints: ['mobile', 'tablet', 'laptop'] })

	// console.log('t', t)

	const theme = createTheme(t, {
		typography: {
			button: { textTransform: 'none' }
			// fontSize: 'clamp(1.1rem, 0.7153rem + 1.6368vw, 1.5rem)'
		},
		components: {
			// This is supposed to work, but gets overwridden palette actions selectedOpacity
			// Mui: {
			// 	styleOverrides: {
			// 		root: {
			// 			'&.Mui-selected': {
			// 				backgroundColor: 'red',
			// 			},
			// 		},

			// 	},
			// },
			Carousel: {
				defaultProps: {},
				styleOverrides: {
					root: {},
					navButton: {
						fontSize: '64px'
					},
					indicator: { width: '20px', height: '20px' },
					indicatorContainer: {}
				}
			},
			VirtualListView: {
				defaultProps: { hover: true, separator: true, focus: true, stickyHeader: true },
				styleOverrides: {
					root: {},
					cell: {},
					title: {},
					focused: {},
					hover: {},
					separator: {},
					stripe: {},
					cellRigthAligned: {},
					cellCentered: {},
					titleRigthAligned: {},
					titleCentered: {},
					titleIsDown: {}
				}
			},
			MuiAutocomplete: {
				styleOverrides: { root: { overflow: 'unset' }, listbox: { backgroundColor: `${alpha(t.palette.primary.main, 0.1)}` } }
			},
			MuiTypography: {
				styleOverrides: { root: {} }
			},
			MuiTabPanel: {
				styleOverrides: { root: { fontSize: 'inherit', width: '100%', padding: '0.25em' } }
			},
			MuiTable: {
				styleOverrides: { root: { fontSize: 'inherit', width: 'auto' } }
			},
			MuiTableHead: {
				styleOverrides: { root: { fontSize: 'inherit' } }
			},
			MuiTableRow: {
				styleOverrides: { root: { fontSize: 'inherit' } }
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						fontSize: 'inherit',
						padding: '0.25em 1em 0.25em 1em',
						border: '1px solid silver'
					}
				}
			},
			MuiTextField: {
				defaultProps: {
					size: 'small'
					// margin: 'dense'
				},
				styleOverrides: {
					// root: { fontSize: 'inherit' }
				}
			},
			MuiMenu: {
				// defaultProps: { transitionDuration: '0s 0s' },
				styleOverrides: {
					root: {},
					list: { fontSize: 'inherit' }
				}
			},
			MuiMenuItem: {
				styleOverrides: {
					root: { fontSize: 'inherit', minHeight: 'inherit' }
				}
			},
			MuiBackdrop: {
				styleOverrides: {
					root: { transition: 'none' }
				}
			},
			MuiInput: {
				styleOverrides: {
					// root: { fontSize: 'inherit' }
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: '1000px',
						fontSize: 'inherit'
					},
					multiline: {
						borderRadius: '0.5em'
					}
					// This creates a large border around multiline inputs, but does not seem to affect regular inputs
					// input: { padding: '0.5em 0.5em 0.5em 0.5em' }
				}
			},
			MuiDialog: {
				styleOverrides: {
					paper: {
						// backgroundColor: 'yellow',
					}
				}
			},
			// MuiTooltip: {
			// 	styleOverrides: {
			// 		tooltip: {
			// 			backgroundColor: t.palette.primary.main,
			// 		},
			// 	},
			// },
			MuiDialogActions: {
				styleOverrides: {
					root: {
						justifyContent: 'space-evenly'
					}
				}
			},
			MuiTabs: {
				styleOverrides: {
					root: {
						alignItems: 'center',
						minHeight: '20px'
						// backgroundColor: 'beige',
						// borderStyle: 'solid'
						// borderWidth: `0 0 1px 0`,
						// borderColor: t.palette.secondary.main,
						// padding: '8px 0 8px 0'
						// borderRadius: '1000px'
					},
					indicator: {},
					flexContainer: { alignItems: 'center' }
				}
			},

			MuiTab: {
				styleOverrides: {
					root: {
						// ['&.Mui-selected']: { backgroundColor: t.palette.primary.main },
						borderRadius: '0.5em 0.5em 0 0',
						backgroundColor: `${alpha(t.palette.primary.main, 0.4)}`,
						border: `1px solid ${t.palette.primary.main}`,
						padding: '0.25em 1em',

						minHeight: '20px',
						// borderStyle: 'solid',
						// borderWidth: `1px`,
						// borderColor: t.palette.primary.main,
						// borderRadius: '8px 8px 8px 8px',
						// zIndex: 100000,
						'&.Mui-selected': {
							backgroundColor: t.palette.primary.main,
							color: t.palette.primary.contrastText
						}
					}
				}
			},
			MuiToggleButton: {
				styleOverrides: {
					root: {
						borderRadius: '1000px',
						padding: '0.25em 1em',
						fontSize: 'inherit'
					}
				}
			},
			MuiDivider: {
				styleOverrides: {
					root: {
						color: t.palette.primary.main,
						'&::before': { top: 0 },
						'&::after': { top: 0 }
					}
				}
			},
			MuiTreeView: {
				defaultProps: {},
				styleOverrides: {}
			},
			MuiTreeItem: {
				styleOverrides: {
					root: {},
					group: {
						transition: 'none'
						// borderLeft: `1px dashed  ${alpha(t.palette.text.primary, 0.4)}`,
					}
				}
			},
			MuiTreeItem2: {
				styleOverrides: {
					root: {},
					content: { padding: '0px' }
				}
			},
			DataTreeView: {
				defaultProps: {
					restrictExpansionToIcon: true
				}
			},
			// BreadCrumb: {
			// 	styleOverrides: {
			// 		root: {
			// 			backgroundColor: 'blue'
			// 		}
			// 	}
			// },
			ComboBox: {
				styleOverrides: {
					root: {},
					option: { backgroundColor: `${alpha(t.palette.primary.main, 0.1)}` }
				},
				defaultProps: {
					size: 'small'
				}
			},
			Edge: {
				defaultProps: {
					cursor: 'pointer',
					thickness: '100px',
					length: '50%'
				},
				styleOverrides: {
					root: {}
				}
			},
			ItemSelector: {
				defaultProps: {
					size: 'small'
				}
			},
			SplitFlex: {
				defaultProps: {
					// these are all default values in the component itself
					// minPrimary: '100px',
					// fill: true,
					// scroll: false,
				},
				styleOverrides: {
					root: {
						// backgroundColor: 'aqua',
						// flex: '1 1 auto',
						// overflow: 'auto',
					},
					splitter: {
						// border: '1px dashed secondary',
						// padding: '1px',
					},
					primary: {
						// border: '1px dashed secondary',
						// padding: '1px',
					},
					secondary: {
						// border: '1px dashed secondary',
						// padding: '1px',
					}
				}
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						// backgroundColor: 'aliceBlue',
						// color: t.palette.primary.main,
					}
				}
			},
			// MuiPaper: {
			//   styleOverrides: {
			//     root: {
			//       // left: '10px',
			//       // backgroundColor: 'aliceBlue',
			//       // color: t.palette.primary.main,
			//     },
			//   },
			// },
			MuiDrawer: {
				styleOverrides: {
					root: {
						// left: '10px',
						// backgroundColor: 'aliceBlue',
						// color: t.palette.primary.main,
					}
					// paper: { margin: '8px' },
				}
			},
			MuiModal: {
				styleOverrides: {
					root: {
						// left: '10px',
						// backgroundColor: 'aliceBlue',
						// color: t.palette.primary.main,
					}
				}
			},
			MuiCheckbox: {
				// this is not needed because we are now doing it in the @app/muiplus library
				// defaultProps: { size: 'inherit' },
				styleOverrides: { root: { lineHeight: 'normal', padding: '0.25em' } }
			},
			MuiFormControlLabel: {
				// this is not needed because we are now doing it in the @app/muiplus library
				// styleOverrides: { root: { margin: '0px' }, label: { fontSize: 'inherit' } }
			},
			MuiButton: {
				defaultProps: {
					variant: 'contained'
					// size: 'medium'
					// color: 'secondary',
					// The props to apply
					// disableRipple: true, // No more ripple, on the whole application 💣!
				},
				styleOverrides: {
					root: { transform: 'none', padding: '0.5em 1em', borderRadius: '1000px', lineHeight: 'normal' }
				},
				variants: [
					{
						props: { variant: 'speed' },
						style: {
							padding: '0px',
							paddingLeft: '0.25em',
							paddingRight: '0.25em',
							margin: '1px',
							fontSize: 'inherit',
							minWidth: 'inherit',
							border: `1px solid ${alpha(t.palette.text.primary, 0.4)}`
						}
					},
					{
						props: { variant: 'dashed' },
						style: {
							textTransform: 'none',
							border: `2px dashed purple}`
						}
					},
					{
						props: { variant: 'dashed', color: 'secondary' },
						style: {
							border: `4px dashed blue}`
						}
					}
				]
			}
		}
	})
	// console.log('Mytheme', theme)

	return theme
}
