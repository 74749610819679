import { Tree, TreeRoot } from '@app/utils'
import { createContext, useContext } from 'react'

interface IMenuContent {
	id?: string
	caption: string
	target: string
	options?: any
}
export type IMenu = Tree<IMenuContent>
export type IMenuList = IMenu[]

class Context {
	items: IMenuList = []
}
export const MenuContext = createContext(new Context())

export const useMenu = () => {
	const val = useContext(MenuContext)
	return val
}
