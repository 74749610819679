import path from 'path'
import anylogger from '@app/anylogger'
import matter from 'gray-matter'
import { WebMenu, WebMenuItem } from '@app/utils'
import { ICloudObject, ICloudStorage } from '@app/cloudstorage'
import { serialize } from 'next-mdx-remote/serialize'
import remarkGfm from 'remark-gfm'
import { MdxMenu, MdxMenuItem } from './MdxMenu'
const log = anylogger('MdxSource')

/**
 * MdxManager (newName) should accept a file system list and provide functions to turn mdx content pages into html pages
 * Responsibilities:
 * * Accept a file source and filter out mdx file content
 * * Be able to create a menu from the mdx file system tree content
 * **  optionally display all items or only those prefixed with numbers for sorting
 * * return the content of an mdx file based on its url path, which is stripped of its number prefix and extension
 * * return serialized content from the mdx content that can be created at compile time and sent to the browser from getStaticProps
 * * look at and apply mdx front matter content options such as prependTitle and prependSummary
 * * compile metadata such as child pages as well as referenced pages and array data (as specified in the front matter)
 *
 */

export type SummaryImageLocation = 'top' | 'left' | 'right' | 'none'

export interface MdxFrontMatter {
	title?: string
	prependTitle?: boolean
	summary?: string
	prependSummary?: boolean
	summaryImage?: string
	prependSummaryImage?: SummaryImageLocation
	prependSummaryImageWidth?: string
	maxContentWidth?: string
	[key: string]: any
}

export class IMdxSource {
	RefreshContent!: () => Promise<void>
	GetSerializedContent!: (content: string, scope: any) => Promise<any>
	GetPaths!: () => Promise<string[][]>
	GetAllFiles!: () => Promise<string[]>
	GetMenu!: (restrictToNumberedPrefixes?: boolean) => WebMenu
	FindEntry!: (dirPath: string[]) => MdxFile | undefined
	FindEntryByString!: (dirPath: string) => MdxFile | undefined
	GetContent!: (entry: MdxFile) => Promise<string>
	SaveContent!: (path: string, content: string) => Promise<void>
	ParseContent!: (rawContent: string) => IParsedMdx
	JoinContent!: (parsedMdx: IParsedMdx) => string
	ApplyPrependedContent!: (content: string, meta: MdxFrontMatter) => string
	GetChildData!: (entry: MdxFile) => Promise<ReferencedPageDataList>
	GetPageData!: (pages: string[] | undefined) => Promise<ReferencedPageDataList>
	GetSinglePageData!: (page: string) => Promise<PageReferenceData>
	getPagesUsingImage!: (imageName: string) => Promise<string[]>
	getUrlFromPath!: (path: any) => Promise<string>
}

export class MdxFile {
	source: ICloudObject
	fileName: string
	filteredName: string
	isDir: boolean
	children?: MdxFileList
	fullPath: string
	urlPath: string

	constructor(source: ICloudObject, filteredName: string, urlPath: string) {
		this.source = source
		this.fileName = source.name
		this.filteredName = filteredName
		this.urlPath = urlPath
		this.isDir = source.isDir
		this.fullPath = source.path
	}
	FindChildByName(name: string): MdxFile | undefined {
		const nodes = this.children && this.children.filter((data) => this.normalize(data.filteredName) === this.normalize(name))
		return nodes && nodes[0]
	}
	normalize(str: string) {
		return str.replace(/ /g, '').toLocaleLowerCase()
	}
}
export type MdxFileList = MdxFile[]

export interface IParsedMdx {
	meta: MdxFrontMatter
	content: string
}

export interface PageReferenceData {
	link: string
	title?: string
	summary?: string
	summaryImage?: string
}
export type ReferencedPageDataList = PageReferenceData[]

type TrimFunction = (val: string) => string

export const trimNumberPrefix = (str: string): string => {
	const matches = str.match(/[0-9]*\-(.*)/)
	const res = matches ? matches[1] : str
	return res
}
export const trimFileExtension = (str: string): string => {
	const fileExt = path.extname(str)
	return path.basename(str, fileExt)
}

interface MdxSourceOptions {
	extensions: string[]
	urlRoot: string
	includeEmptyDirectories: boolean
	nameFilters?: TrimFunction[]
	instanceName: string
}

export class MdxSource implements IMdxSource {
	extensions: string[] = []
	urlRoot: string = ''
	instanceName: string = ''
	includeEmptyDirectories: boolean = true
	nameFilters: TrimFunction[] = [trimNumberPrefix, trimFileExtension]
	mdxFile: MdxFile
	api: ICloudStorage

	static async Create(contentSource: ICloudStorage, options: MdxSourceOptions) {
		const res = new MdxSource(contentSource, options)
		await res.addChildren(res.mdxFile, options.urlRoot)
		return res
	}
	private constructor(api: ICloudStorage, options: MdxSourceOptions) {
		Object.assign(this, options)
		// log.warn('Creating MdxSource')

		this.api = api
		this.extensions = this.extensions.map((ext) => ext.toLowerCase())
		this.mdxFile = new MdxFile({ name: 'root', isDir: true, path: '/' } as ICloudObject, '', this.urlRoot)
	}
	async RefreshContent() {
		// log(`RefreshContent (${this.instanceName})`)

		this.mdxFile = new MdxFile({ name: 'root', isDir: true, path: '/' } as ICloudObject, '', this.urlRoot)
		await this.addChildren(this.mdxFile, this.urlRoot)
	}

	async GetPaths(): Promise<string[][]> {
		let paths: string[][] = []
		const addEntries = (data: MdxFile, parents: string[]) => {
			if (!data?.children) return

			let haveIndex = false
			data.children.forEach((entry) => {
				if (entry.filteredName.toLocaleLowerCase() === 'index') {
					haveIndex = true
					return
				}
				const currentPath = [...parents, this.RemoveSpaces(entry.filteredName)]
				if (!entry.isDir) paths.push(currentPath)
				addEntries(entry, currentPath)
			})
			if (haveIndex) paths.push([...parents])
		}
		addEntries(this.mdxFile, [])
		return paths
	}
	async GetAllFiles(): Promise<string[]> {
		let paths: string[][] = []
		const addEntries = (data: MdxFile, parents: string[]) => {
			if (!data?.children) return

			let haveIndex = false
			data.children.forEach((entry) => {
				if (entry.fileName.toLocaleLowerCase() === 'index') {
					haveIndex = true
					return
				}
				const currentPath = [...parents, entry.fileName]
				if (!entry.isDir) paths.push(currentPath)
				addEntries(entry, currentPath)
			})
			if (haveIndex) paths.push([...parents])
		}
		addEntries(this.mdxFile, [])
		return paths.map((path) => path.join('/'))
	}

	async GetParsedContent(entry: MdxFile): Promise<IParsedMdx> {
		const content = await this.GetContent(entry)
		if (!content) return { meta: {}, content: '' }
		return this.ParseContent(content)
	}

	async GetContent(entry: MdxFile): Promise<string> {
		let effective: any = entry
		if (entry.isDir) {
			const e = entry.FindChildByName('index')

			if (!e) {
				effective = undefined
				// log.error('Could not find directory index for ', entry.fullPath)
				// return 'Could not find content'
			} else effective = e
		}
		let content = ''
		if (effective) {
			const base64 = await this.api.getContent(effective.source)
			content = Buffer.from(base64, 'base64').toString('utf-8')
		} else {
			content = `---\ntitle: ${entry.filteredName}\n---\n<PageCards pageData={childData}/>`
		}
		return content
	}
	async SaveContent(path: string, content: string): Promise<void> {
		const base64 = Buffer.from(content).toString('base64')
		await this.api.upsertObject({ path, base64, contentType: 'text/plain' })
	}
	async DeleteContent(path: string): Promise<void> {
		await this.api.deleteObject({ path })
	}
	ParseContent(rawContent: string): IParsedMdx {
		// passing an empty options object prevents gray-matter from caching the data.
		// If cached, the returned data is actually a MUTABLE object.  So, if you call matter with string content,
		// then modify the returned data object, and then request the same string content again, you will get
		// the MODIFIED data object, which is likely NOT what you expect.
		const { data: meta, content } = matter(rawContent, {})

		return { meta, content }
	}
	JoinContent(parsedMdx: IParsedMdx): string {
		const res = matter.stringify(parsedMdx.content, parsedMdx.meta, {})
		return res
	}
	ApplyPrependedContent = (content: string, meta: MdxFrontMatter) => {
		let {
			title,
			summary,
			prependTitle = true,
			prependSummary = true,
			prependSummaryImage = 'none',
			prependSummaryImageWidth = 300
		} = meta
		let prepended = ''
		if (prependTitle && title) prepended = prepended + '<h1 style={{textAlign: "center"}}>' + title + '</h1>\n'

		if (prependSummaryImage !== 'none' && meta.summaryImage) {
			const pos =
				prependSummaryImage === 'left'
					? 'float="left"'
					: prependSummaryImage === 'right'
						? 'float="right"'
						: 'marginX="auto" display="block"'
			let image = `<Image src="${meta.summaryImage}" ${pos} width="${Number(prependSummaryImageWidth)}px" />`
			if (prependSummaryImage == 'top') image = `<div width="100%">${image}</div>`
			prepended = prepended + image + '\n\n'
		}
		if (prependSummary && summary) prepended = prepended + summary + '<br/><br/>\n'

		content = prepended + content

		return content
	}
	async GetChildData(entry: MdxFile): Promise<ReferencedPageDataList> {
		let children: ReferencedPageDataList = []
		if (entry.children) {
			for (const child of entry.children) {
				if (child.filteredName === 'index') continue
				const rawContent = await this.GetContent(child)

				if (!rawContent) continue
				const { meta } = this.ParseContent(rawContent)
				// const childPath = path.join(entry.fullPath, this.RemoveSpaces(child.filteredName))
				children.push({ ...meta, link: child.urlPath })
			}
		}
		return children
	}
	async GetPageData(pages: string[] | undefined): Promise<ReferencedPageDataList> {
		let res: ReferencedPageDataList = []
		if (Array.isArray(pages)) {
			for (const page of pages) {
				const entry = await this.GetSinglePageData(page)
				res.push(entry)
			}
		}
		return res
	}
	async GetSinglePageData(page: string): Promise<PageReferenceData> {
		const entry = this.FindEntryByString(page)
		if (!entry) return { title: `Incorrect page reference: ${page}`, link: '' }
		else {
			const rawContent = await this.GetContent(entry)
			if (!rawContent) return { title: `No content on page: ${page}`, link: '' }
			const { meta } = this.ParseContent(rawContent)
			return { ...meta, link: entry.urlPath }
		}
	}
	GetMenu = (restrictToNumberedPrefixes: boolean = true) => {
		const res: MdxMenu = []
		// log(`GetMenu (${this.instanceName})`)
		const addMenus = (data: MdxFile, menu: MdxMenu, path: string) => {
			if (!data?.children) return

			data.children.forEach((entry) => {
				if (entry.filteredName.toLocaleLowerCase() === 'index') return
				// anly add pages prefixed with numbers to the menu
				const match = entry.fileName.match(/[0-9].*/)
				if (!match && restrictToNumberedPrefixes) return

				// const name = entry.filteredName
				// const newPath = path + '/' + this.RemoveSpaces(name)

				const m: MdxMenuItem = {
					caption: entry.filteredName,
					// url: !entry.isDir || entry.FindChildByName('index') ? entry.urlPath : '',
					url: entry.urlPath,
					isEditable: true
				}
				if (entry.children) {
					m.children = []
					addMenus(entry, m.children, entry.urlPath)
				}
				menu.push(m)
			})
		}
		addMenus(this.mdxFile, res, '')
		return res
	}

	FindEntryByString = (dirPath: string) => {
		return this.FindEntry(dirPath.split('/'))
	}
	FindEntry = (dirPath: string[]): MdxFile | undefined => {
		// log(`FindEntry (${this.instanceName}) ${dirPath}`)
		let curNode: MdxFile | undefined = this.mdxFile
		for (const dirPart of dirPath) {
			if (!dirPart) continue

			curNode = curNode.FindChildByName(this.applyFilters(dirPart))
			// console.log('dirPart', dirPart, curNode?.filteredName)
			if (!curNode) {
				log.error(`(${this.instanceName}): Could not find ${JSON.stringify(dirPart)}`)
				return undefined
			}
		}
		return curNode
	}
	async getPagesUsingImage(imageName: string): Promise<string[]> {
		const pages: string[] = []
		imageName = imageName.toLocaleLowerCase()
		const findPages = async (data: MdxFile, parents: string[]) => {
			if (!data?.children) return

			let haveIndex = false
			for (let ii = 0; ii < data.children.length; ii++) {
				const entry = data.children[ii]
				const currentPath = [...parents, this.RemoveSpaces(entry.filteredName)]
				const rawContent = await this.GetContent(entry)
				if (rawContent.toLocaleLowerCase().includes(imageName)) {
					pages.push(currentPath.join('/'))
				}
				await findPages(entry, currentPath)
			}
		}
		await findPages(this.mdxFile, [])
		return pages
	}
	addChildren = async (mdxFile: MdxFile, urlRoot: string) => {
		const files = await this.api.getDirectory(mdxFile.source)
		// log('files', files)

		const children: MdxFileList = []
		// .map and foreach do NOT wait for async calls.  for...of does.
		for (const file of files.list) {
			if (file.name.startsWith('.')) continue

			const fileExt = path.extname(file.name)
			// if extensions are specified, do not include any extensions that do not match
			if (!file.isDir && this.extensions.length && !this.extensions.includes(fileExt)) continue

			const filteredName = this.applyFilters(file.name)
			// const newPath = path.join(fullPath, file.fileName)
			const newRoot = urlRoot + '/' + this.RemoveSpaces(filteredName)
			let data = new MdxFile(file, filteredName, newRoot)
			data.filteredName = filteredName

			if (file.isDir) {
				// log('dir', file.fileName)
				// log('fullpath', newPath, newRoot)

				await this.addChildren(data, newRoot)
				const haveChildren = data.children && data.children.length > 0
				// console.log(file.fileName, haveChildren)
				if (!haveChildren && !this.includeEmptyDirectories) continue
			}
			children.push(data)
		}
		children.filter(Boolean).sort(this.compareDirData)

		mdxFile.children = children as MdxFile[]
	}
	async GetSerializedContent(content: string, scope: any): Promise<any> {
		let serialized = await serialize(content, {
			scope,
			mdxOptions: {
				remarkPlugins: [remarkGfm],
				rehypePlugins: []
			}
		})
		return serialized
	}
	getUrlFromPath(path: string): Promise<string> {
		const dirs = path.split('/')
		const filtered = dirs.map((dir) => this.RemoveSpaces(this.applyFilters(dir)))
		const filteredName = filtered.join('/')
		return Promise.resolve(filteredName)
	}
	applyFilters(str: string): string {
		this.nameFilters.forEach((filter) => {
			str = filter(str)
		})
		return str
	}
	compareDirData = (a?: MdxFile, b?: MdxFile) => {
		if (!a || !b) return 0
		let res = a.fileName!.localeCompare(b.fileName!)
		return res
	}
	RemoveSpaces = (str: string) => {
		return str.replace(/ /g, '')
	}
}
