import { Box, BoxProps, SxProps, Theme } from '@mui/material'
import anylogger from '@app/anylogger'
import { Property } from 'csstype'
import React from 'react'

const log = anylogger('Flex')

export interface FlexProps extends BoxProps {
	/**
	 * A shorthand for flexFlow using single characters. The default value is 'r'.
	 * The syntax is (r|c)[r][w[r]] where r is row and c is column.
	 * * The optional r after r or c is reverse.  ie.
	 *   * c - column
	 *   * r - row
	 *   * rr - row-reverse
	 *   * cr - column-reverse
	 * * The optional w indicates wrap, and if it is followed by r, it indicates reverse. i.e.
	 *   * rw - row wrap
	 *   * crw - column-reverse wrap
	 *   * rrwr - row-reverse wrap-reverse
	 *
	 * Please note that you can still use the property names values if you prefer, although MUI does not support flexFlow,
	 * so you would need to use flexDirextion and FlexWrap.
	 */
	f?: string
	/**
	 * This represents the gap for the Flex, and defaults to {1}, which is a spacing unit of 1 in the MUI theme.
	 * You can override this by specifying gap, gapColumn or gapRow properties.
	 */
	gap?: string | number
	/**
	 * A shorthand for a container that will take up the remaining room of its parent.  i.e flex='1 1 auto'.
	 * This will take up any remaining height or width of the parent Flex container, depending on the main axis of that parent container.
	 * You can easily use the flex, flexGrow, flexShrink and flexBasis props directly for finer tuned control of the child components.
	 *
	 * This also automatically adds the scroll property so that if the child content overflows this flex, it will cause a scroll bar on this flex
	 * instead of passing the scroll requirement up the parent chain.
	 * You can explicity disable automatically adding scroll by explicitly passing scroll="false", * or overflow="xxx".
	 */
	fill?: boolean
	/**
	 * Normally (and you should strive for this), a non-'fill' child will be sized to its content.  But, if you explicitly set the size
	 * of a child, that panel will shrink if required until all the whitespace is eaten up.  This is because flex-shrink is set to 1 by default.
	 * To force a child to retain its height, and not split it with any other flex-shrink siblings, use this property, which is a shorthand for
	 * flex-shrink: 0.  You do not need this unless you explicitly set the size of the flex, which you should try to avoid doing.
	 *
	 */
	fixed?: boolean
	/**
	 * This is a shorthand for overflow: 'auto'.  It is automatically applied with the 'fill' prop, because normally, you would want a 'fill'
	 * pane to scroll itself instead of causing its parent to scroll.
	 *
	 * NOTE:  In order for scroll to work, it seems that all parents must have scroll turned on.
	 */
	scroll?: boolean
	/**
	 * Just adding this because it was not a part of MuiBox
	 */
	cursor?: string
	/**
	 * sx
	 */
	sx?: SxProps
}
/**
 * A Flex component that simplifies specifying the Flex configuration. In addition to all normal Flex css props, the following
 * {@link FlexProps} are added.
 * * f: A shortcut with shortcut syntax for flexFlow: i.e. 'r', 'c', 'rw'
 * * fill: Takes up the remaining room on the main axis in a _parent_ flex component. (i.e. it is used in children of a parent)
 * * scroll: A shorthand for overflow: 'auto'
 *
 * There are also FlexC and FlexR components that use default props of f="c" and f="r" respectively so that you don't even
 * have to add a property at all for simple cases
 *
 */
export const Flex = React.forwardRef(function Flex(props: FlexProps, ref: any) {
	let { f, fill, scroll, fixed, sx, cursor, style, ...rest } = props
	const [dir, wrap] = extractValues(f, fill)

	let adjustedSx: SxProps<Theme> = { ...sx }

	let flexProps: any = {}
	if (fill) {
		flexProps = { ...flexProps, flexGrow: '1' }
		// only add scroll if it is explicitly not specified
		if (typeof scroll == 'undefined') scroll = true
	}
	if (fixed) flexProps = { ...flexProps, flexShrink: '0' }

	if (scroll) adjustedSx = { overflow: 'auto', ...adjustedSx }
	style = { ...style, cursor }

	// log('props', props)
	// log('fillObj', fillObj)
	// log('adjustedSx', adjustedSx)

	return (
		<Box
			ref={ref}
			display="flex"
			flexDirection={dir as Property.FlexDirection}
			flexWrap={wrap as Property.FlexWrap}
			gap={1}
			style={style}
			{...flexProps}
			sx={adjustedSx}
			{...rest}
		></Box>
	)
})

export const FlexC = React.forwardRef(function FlexC(props: FlexProps, ref: any) {
	return <Flex f="c" ref={ref} {...props}></Flex>
})
export const FlexR = React.forwardRef(function FlexR(props: FlexProps, ref: any) {
	return <Flex flexDirection="row" ref={ref} alignItems="center" {...props}></Flex>
})

const extractValues = (f: string | undefined, fill: boolean | undefined) => {
	const flow: string = f ? f.toLowerCase() : 'r'

	const isRow = flow.startsWith('r')
	if (!isRow && !flow.startsWith('c')) throw new Error("The 'flex' property must start with either r or c to indicate row or column")

	let dir: string = isRow ? 'row' : 'column'
	let wrapPart = ''
	let wrap = undefined
	if (flow.length > 1) {
		if (flow[1] == 'r') {
			dir = dir + '-reverse'
			wrapPart = flow.slice(2, 4)
		} else {
			wrapPart = flow.slice(1, 3)
		}
		if (wrapPart[0] == 'w') {
			wrap = 'wrap'
			if (wrapPart[1] == 'r') wrap = wrap + '-reverse'
			else if (wrapPart.length > 1) throw new Error(`Invalid f parameter: ${f}`)
		} else if (wrapPart.length > 0) throw new Error(`Invalid f parameter: ${f}`)
	}
	return [dir, wrap]
}
