import anylogger from '@app/anylogger'
import { useLocalStorageOrState } from '@app/hooks'
import { useThemeProps } from '@mui/material'
import { useTreeViewApiRef } from '@mui/x-tree-view'
import {
	TreeViewPublicAPI,
	UseTreeViewExpansionSignature,
	UseTreeViewFocusSignature,
	UseTreeViewIconsSignature,
	UseTreeViewItemsSignature,
	UseTreeViewKeyboardNavigationSignature,
	UseTreeViewLabelSignature,
	UseTreeViewSelectionSignature
} from '@mui/x-tree-view/internals'
import { SimpleTreeView, SimpleTreeViewProps } from '@mui/x-tree-view/SimpleTreeView'
import { RefObject, useCallback, useEffect, useState } from 'react'
import { CustomTreeItem } from './TreeItem'
import { ITreeViewItem, ITreeViewProvider } from './TreeViewProvider'
import { TreeViewProviderAdapter } from './TreeViewProviderAdapter'

const log = anylogger('TreeView')

export type TreeViewApi = RefObject<
	| TreeViewPublicAPI<
			readonly [
				UseTreeViewItemsSignature,
				UseTreeViewExpansionSignature,
				UseTreeViewSelectionSignature,
				UseTreeViewFocusSignature,
				UseTreeViewKeyboardNavigationSignature,
				UseTreeViewIconsSignature,
				UseTreeViewLabelSignature
			]
	  >
	| undefined
>
interface TreeViewProps<T extends object = any, Multiple extends boolean = false> extends SimpleTreeViewProps<Multiple> {
	/**
	 * The data to be displayed that implements the ITreeNodeData interface */
	provider: ITreeViewProvider<T>

	/**
	 * this will fill the parent container with the tree view */
	fill?: boolean

	/**
	 * If true, only expands or collapses the node if you click on the icon. */
	restrictExpansionToIcon?: boolean

	/**
	 * Default: undefined. Specifies the local storage key to store the expanded and selected nodes so that they are
	 * retained across browser refreshes.
	 */
	localStorageKey?: string

	/**
	 * If specified, these are the nodeIds of the selected items. Otherwise, the TreeView itself remembers
	 * what is selected, and if localStorageKey is specified, this is persisted across sessions
	 */
	selected?: Multiple extends true ? string[] : string

	/**
	 * This callback is fired when a tree node item is selected.
	 * data is the original data element supplied to the adapter.
	 * nodeData is the ITreeNodeData item (adapted item)
	 *  */
	onItemSelected?: (e: any, item: T) => void

	onlyShowEndIconOnHover?: boolean
}
/**
 * This is a wrapper around TreeView that allows passing in a data object (or list of objects) that implement ITreeNodeData.
 * This allows you to create an adapter for any tree-like object and display it in the tree view. ITreeNodeData supports an async
 * getChildren method so that children are queried asynchronously.
 *
 * When a node's children are displayed, all those children
 * are queried to see if they have children, so there is a visual indication of those children.
 *
 * The styling is inherited entirely from MUI TreeView/TreeItem.
 */
export function TreeView<T extends object = any, Multiple extends boolean = false>(inProps: TreeViewProps<T, Multiple>) {
	type SelectType = Multiple extends true ? string[] : string
	const props = useThemeProps({ props: inProps, name: 'TreeView' })

	const {
		provider,
		fill,
		sx,
		localStorageKey,
		selected: clientSelected,
		onItemSelected: onTreeNodeClick,
		restrictExpansionToIcon = false,
		onlyShowEndIconOnHover = false,
		// fontSize,
		...rest
	} = props
	const { multiSelect } = rest

	// const [items, setItems] = useState<TreeNodeList>([])
	// const [iterator, setIterator] = useState(new TreeIterator<TreeNode>([]))

	// if (fontSize) sxProps = { ...sxProps, '& .MuiTreeItem-label': { fontSize: fontSize } }

	const [refreshCount, setRefreshCount] = useState(0)
	const refresh = useCallback(() => {
		setRefreshCount((prev) => prev + 1)
	}, [])

	const expandedKey = localStorageKey ? localStorageKey + '.expanded' : undefined
	const selectedKey = localStorageKey ? localStorageKey + '.selected' : undefined
	const [expanded, setExpanded] = useLocalStorageOrState<string[]>(expandedKey, [])
	const [selected, setSelected] = useLocalStorageOrState<SelectType>(selectedKey, (multiSelect ? [] : '') as SelectType)
	const [adapter, setAdapter] = useState<TreeViewProviderAdapter<T>>(new TreeViewProviderAdapter(provider))
	const [items, setItems] = useState<ITreeViewItem<T>[]>([])
	const apiRef = useTreeViewApiRef()

	useEffect(() => {
		setAdapter(new TreeViewProviderAdapter(provider))
		provider.setApi(apiRef, refresh)
	}, [apiRef, provider, refresh])
	useEffect(() => {
		adapter.getItems(expanded).then((items) => {
			setItems(items)
			refresh()
		})
	}, [adapter, expanded, refresh])
	let sxProps: any = { ...sx, flexGrow: 1 }
	if (fill) sxProps = { display: 'inlink-block', width: '100%', ...sxProps }

	const onExpandedChanged = useCallback(
		(e: any, nodeIds: string[]) => {
			setExpanded(nodeIds)
		},
		[setExpanded]
	)
	const onExpandedToggle = useCallback(async (e: any, itemId: string, isExpanded: boolean) => {
		// this is likely not necessary as we are passing the expanded (list) to adapter.getItems, and the useEffect is dependent on that.
	}, [])
	const onSelectionToggle = useCallback(
		async (e: any, itemId: string, isSelected: boolean) => {
			setSelected((prev) => {
				if (isSelected) {
					if (typeof prev === 'string') return itemId as SelectType
					if (multiSelect && !prev.includes(itemId)) {
						return [...(prev as string[]), itemId] as SelectType
					}
					return prev
				} else {
					if (typeof prev === 'string') return '' as SelectType
					if (prev.includes(itemId)) {
						return prev.filter((id) => id != itemId) as SelectType
					}
				}
				return prev as SelectType
			})
			if (isSelected) {
				const item = adapter.getItemById(items, itemId)
				if (item && onTreeNodeClick) {
					onTreeNodeClick(e, item.source)
				}
			}
		},
		[adapter, items, multiSelect, onTreeNodeClick, setSelected]
	)
	const renderChildren = useCallback(
		(item?: ITreeViewItem<T>) => {
			const children = item ? (item.children ?? []) : items

			return children.map((item: any) => {
				return (
					<CustomTreeItem
						key={item.id}
						itemId={item.id}
						label={item.label}
						endIcon={item?.endIcon}
						onlyShowEndIconOnHover={onlyShowEndIconOnHover}
						sxProps={item.sxProps}
						// icon={child.icon}
						// endIcon={child.endIcon}
						// hasChildren={child.hasChildren}
						// slots={{ content: TreeItemContentOnlyIconExpand }}
					>
						{renderChildren(item)}
					</CustomTreeItem>
				)
			})
		},
		[items, onlyShowEndIconOnHover]
	)

	return (
		<SimpleTreeView
			apiRef={apiRef}
			sx={sxProps}
			onExpandedItemsChange={onExpandedChanged}
			onItemExpansionToggle={onExpandedToggle}
			expansionTrigger={restrictExpansionToIcon ? 'iconContainer' : 'content'}
			expandedItems={expanded}
			selectedItems={typeof clientSelected != 'undefined' ? clientSelected : selected}
			onItemSelectionToggle={onSelectionToggle}
			{...rest}
		>
			{renderChildren()}
		</SimpleTreeView>
	)
}

// NOTE: No point in doing this as this only allows you to override the root style as that is all we are specifying here.
// Any other slots, such as group, etc. are still called MuiTreeItem-group
// const DataTreeViewRoot = styled(TreeView, {
// 	name: 'DataTreeView',
// 	slot: 'Root',
// 	overridesResolver: (props: any, styles: any) => [styles.root],
// })(({ theme }) => ({}))
// const DataTreeItemRoot = styled(TreeItem, {
// 	name: 'DataTreeItem',
// 	slot: 'Root',
// 	overridesResolver: (props: any, styles: any) => [styles.root],
// })(({ theme }) => ({}))
