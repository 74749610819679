import { CalendarPageLink } from 'components/CalendarCards'
import { GithubContent } from './WebEditorApi'
import { WebMenu } from '@app/utils'
import Mail from 'nodemailer/lib/mailer'

export class IWebEditorApi {
	getMemberMenu!: () => Promise<WebMenu>
	getUrlFromPath!: (page: string) => Promise<string>
	savePage!: (page: string, content: string, name: string, email: string, sha?: string, message?: string) => unknown
	deletePage!: (path: string, name: string, email: string, message?: string, status?: (status: string) => void) => Promise<void>
	getEvents!: (startDate: string, endDate: string) => Promise<CalendarPageLink[]>
	getContent!: (page: string) => Promise<GithubContent>
	updateServerContent!: (path: string, content: string) => Promise<void>
	deleteServerContent!: (path: string) => Promise<void>
	sendEmail!: (to: string, from: string, subject: string, body: string, attachments?: Mail.Attachment[]) => Promise<void>
}
