import anylogger from '@app/anylogger'
import { FlexC, No, OK, Text, useModal, Yes } from '@app/muiplus'
import PromptForNewPageName from 'components/PromptForNewPageName'
import { useRouter } from 'next/router'
import React, { useCallback, useContext } from 'react'
import { CachedPage, useWebEditorService } from './WebEditorService'
import { formatDateTime } from '@app/utils'
import { useSession } from '../useSession'

const log = anylogger('WebEditorServiceUI')

export interface IWebEditerServiceUI {
	editPage: (page: string) => void
	addPage: (baseUrl: string) => void
	publishPage(path: string, cachedPage: CachedPage): void
	promptToCancel(path: string, cachedPage: CachedPage | undefined): Promise<string>
	deletePage: (url: string) => void
	archivePage: (url: string) => void
}

export const WebEditorServiceUIContext = React.createContext<IWebEditerServiceUI>(undefined!)

export function useWebEditorServiceUI(): IWebEditerServiceUI {
	const context = useContext(WebEditorServiceUIContext)
	return context
}

export const WebEditorServiceUI = (props: any) => {
	const router = useRouter()
	const { textDialog, alertDialog, customDialog, yesNoPrompt, busyDialog } = useModal()
	const svc = useWebEditorService()
	const [session] = useSession()

	const editPage = useCallback(
		(page: string) => {
			router.push('/PageEditor?page=' + page)
		},
		[router]
	)
	const promptForNewFileName = useCallback(
		async (urlPath: string) => {
			// get the real directory, including number prefixes
			const parentPath = await svc.getFullPath(urlPath)

			const res = await customDialog({
				title: 'Enter the name of the new page',
				dialog: <PromptForNewPageName parentPath={parentPath} />
			})
			log('res', res)

			if (res.result != OK) return undefined
			if (!res.data.toLocaleLowerCase().endsWith('.mdx')) res.data += '.mdx'

			return res.data
		},
		[customDialog, svc]
	)
	const addPage = useCallback(
		async (baseUrl: string) => {
			if (!baseUrl) return
			const newPath = await promptForNewFileName(baseUrl)

			if (!newPath) return

			router.push('/PageEditor?newPage=' + newPath)
		},
		[promptForNewFileName, router]
	)
	const publishPage = useCallback(
		async (path: string, cachedPage: CachedPage) => {
			if (!path || !cachedPage) return
			let url = '/Home'
			const { cancel, setContent } = busyDialog({
				title: 'Saving Page...',
				preserveContentWhitespace: true,
				sx: { maxWidth: '600px' }
			})
			try {
				if (!session?.user?.name || !session?.user?.email) {
					throw new Error(`Cannot save page because user is not logged in.`)
				}
				url = await svc.savePage(path, cachedPage, session.user.name, session.user.email, setContent)
			} catch (err: any) {
				await alertDialog({ title: 'Error Saving', content: err.message })
			} finally {
				cancel()
				router.push(url)
			}
		},
		[alertDialog, busyDialog, router, session?.user?.email, session?.user?.name, svc]
	)
	const promptToCancel = useCallback(
		async (path: string, cachedPage: CachedPage | undefined): Promise<string> => {
			const hasChanges = svc.hasChanges(cachedPage)
			if (hasChanges) {
				const res = await customDialog({
					title: 'Are you sure?',
					dialog: <>'Are you sure you want to cancel and discard the changes you have made?'</>,
					buttons: [
						{ id: Yes, caption: 'Discard Changes' },
						{ id: No, caption: 'Continue Editing' },
						{ id: 1, caption: 'Save for Later' }
					]
				})

				if (res.result == No) return ''
				if (res.result == 1) return '/Members/WebEditor'
			}
			const url = await svc.cancelPage(path)
			return url
		},
		[customDialog, svc]
	)
	const deletePage = useCallback(
		async (url: string) => {
			if (!url) return
			if (!session?.user?.name || !session?.user?.email) {
				throw new Error(`Cannot delete page because user is not logged in.`)
			}

			const res = await yesNoPrompt({
				title: 'Are you sure you want to delete this page?',
				content: (
					<FlexC fill>
						<Text mx="auto">{url}</Text>
						<Text mx="auto" color="red">
							This cannot be undone!
						</Text>
					</FlexC>
				)
			})
			if (res != Yes) return
			const { cancel, setContent } = busyDialog({
				title: 'Deleting Page...',
				preserveContentWhitespace: true,
				sx: { maxWidth: '600px' }
			})

			try {
				await svc.deletePage(url, session.user.name, session.user.email, setContent)
				router.push('/Home')
			} finally {
				cancel()
			}
		},
		[busyDialog, router, session?.user?.email, session?.user?.name, svc, yesNoPrompt]
	)
	const archivePage = useCallback(
		async (url: string) => {
			if (!url) return
			if (!session?.user?.name || !session?.user?.email) {
				throw new Error(`Cannot archive page because user is not logged in.`)
			}

			let dirs = url.split('/')
			const dateStr = formatDateTime(new Date(), 'yyyy-mm-dd')
			const newPageName = `${dateStr} - ${dirs[dirs.length - 1]}.mdx`
			dirs[dirs.length - 1] = newPageName
			const newUrl = '/Archive' + dirs.join('/')
			const res = await yesNoPrompt({
				title: 'Are you sure you want to archive this page?',
				content: (
					<FlexC fill>
						<Text mx="auto">{url}</Text>
						<Text mx="auto">{`The new page name will be ${newUrl}`}</Text>
						<Text mx="auto" color="red">
							This cannot be undone!
						</Text>
					</FlexC>
				)
			})
			if (res != Yes) return
			const { cancel, setContent } = busyDialog({
				title: 'Archiving Page...',
				preserveContentWhitespace: true,
				sx: { maxWidth: '600px' }
			})

			try {
				await svc.archivePage(url, newPageName, session.user.name, session.user.email, setContent)
				router.push('/Home')
			} finally {
				cancel()
			}
		},
		[busyDialog, router, session?.user?.email, session?.user?.name, svc, yesNoPrompt]
	)

	const ctx = { editPage, addPage, publishPage, promptToCancel, deletePage, archivePage }
	return <WebEditorServiceUIContext.Provider value={ctx}>{props.children}</WebEditorServiceUIContext.Provider>
}
