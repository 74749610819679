import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import anylogger from '@app/anylogger'
import { ComboBox, ComboBoxProvider } from './ComboBox'
import { ComboBoxAdapter } from './adapters/ComboBoxAdapter'
import { Dialog } from './Dialog'
import { Text } from './Text'
import { Button, CardContentClassKey, DialogActions, SxProps, TextField } from '@mui/material'
import { DeferredPromise } from '@app/utils'
import { Flex, FlexR } from './Flex'

const log = anylogger('ModalDialog')

export const Yes = -1
export const No = -2
export const OK = -3
export const Cancel = -4
export const Save = -5
export const Discard = -6
export type ButtonType = typeof Yes | typeof No | typeof OK | typeof Cancel | typeof Save | typeof Discard | number

type ModalButton = {
	id: number
	caption: string
	isDefault?: boolean
	isCancel?: boolean
}
export type ModalButtonList = ModalButton[]

export const Buttons = {
	Yes: { id: Yes, caption: 'Yes' },
	No: { id: No, caption: 'No' },
	OK: { id: OK, caption: 'OK', isDefault: true },
	Cancel: { id: Cancel, caption: 'Cancel', isCancel: true },
	Save: { id: Save, caption: 'Save', isDefault: true },
	Discard: { id: Discard, isCancel: true, caption: 'Discard' }
}

export interface BaseModalDialogProps {
	deferredPromise?: DeferredPromise
}
interface DialogButtonsProps {
	buttons?: ModalButtonList
	onClick?: (id: number) => void
}
export const DialogButtons = React.forwardRef(function DialogButtons(props: DialogButtonsProps, ref: any) {
	const { buttons, onClick } = props

	const renderButtons = () => {
		if (!buttons) return null
		const rendered = buttons.map((b, idx) => (
			<Button
				key={idx}
				onClick={() => {
					if (onClick) onClick(b.id)
				}}
			>
				{b.caption}
			</Button>
		))
		return <DialogActions>{rendered}</DialogActions>
	}

	return renderButtons()
})
export interface SimpleDialogProps extends BaseModalDialogProps {
	/**
	 * The title of the dialog.  Can be an element, but is typically text. */
	title?: React.ReactNode
	/**
	 * The content of the dialog can be an element or text */
	content?: React.ReactNode
	/**
	 * The buttons of the dialog as defined by an array of {@link ModalButton} */
	buttons?: ModalButtonList
	/**
	 * Whether the whitespace in the content should be honoured.  If not set any newlines will be ignored. */
	preserveContentWhitespace?: boolean
	/**
	 * The styles to apply to the content of the dialog */
	sx?: SxProps
	/**
	 * If specified, this will be called when a button is clicked.  The called function will then have the choice of
	 * resolving the promise or not. */
	onButtonClick?: (deferredPromise: DeferredPromise, buttonId: number) => void
}
export const SimpleDialog = function SimpleDialog(props: SimpleDialogProps) {
	const { title, content, buttons, deferredPromise, onButtonClick, preserveContentWhitespace = false, sx } = props
	if (!deferredPromise) throw new Error(`no deferredPromise was passed to SimpleDialog`)
	let contentParams = {}
	if (preserveContentWhitespace) contentParams = { ...contentParams, whiteSpace: 'pre' }
	function getTitle() {
		if (typeof title != 'string') return title
		return <Text sx={{ whiteSpace: 'pre' }}>{title}</Text>
	}
	function getContent() {
		if (typeof content != 'string') return content
		// removed white space pre so that the dialog could format
		// return <Text sx={{ whiteSpace: 'pre' }}>{content}</Text>
		return <Text {...contentParams}>{content}</Text>
	}
	const buttonClick = useCallback(
		(buttonId: number) => {
			if (onButtonClick) {
				// if an onButtonClick is provided, then we call that instead of resolving the promise
				onButtonClick(deferredPromise, buttonId)
				return
			}
			if (deferredPromise) deferredPromise.resolve(buttonId)
		},
		[deferredPromise, onButtonClick]
	)
	type FindFunc = (b: ModalButton) => boolean
	const invokeButton = useCallback(
		(findFunc: FindFunc, e: KeyboardEvent, returnVal?: number) => {
			const b = buttons?.find((b) => findFunc(b))
			if (b) {
				const retVal = typeof returnVal != 'undefined' ? returnVal : b.id
				buttonClick(retVal)
				e.stopPropagation()
				e.preventDefault()
				return true
			}
			return false
		},
		[buttonClick, buttons]
	)
	const keyDown = useCallback(
		(e: any) => {
			if (!buttons) return
			if (e.key == 'Enter') {
				if (e.defaultPrevented) log('prevented Default Enter')
				else invokeButton((b) => b.isDefault ?? false, e)
			} else if (e.key == 'Escape') {
				if (e.defaultPrevented) log('prevented Default Escape')
				else if (!invokeButton((b) => b.isCancel ?? false, e)) {
					if (buttons.length == 1) invokeButton((b) => b.id == OK, e, Cancel)
				}
			} else if (e.key.toLocaleLowerCase() == 'y') {
				invokeButton((b) => b.id == Yes, e)
			} else if (e.key.toLocaleLowerCase() == 'n') {
				invokeButton((b) => b.id == No, e)
			}
		},
		[buttons, invokeButton]
	)
	return (
		<Dialog
			open={true}
			title={getTitle()}
			content={getContent()}
			onKeyDown={keyDown}
			sx={{ '.MuiPaper-root': { maxWidth: 'none' }, ...sx }}
		>
			<DialogButtons buttons={buttons} onClick={buttonClick} />
		</Dialog>
	)
}

export interface YesNoDialogProps extends SimpleDialogProps {}
export const YesNoDialog = function YesNoDialog(props: YesNoDialogProps) {
	return <SimpleDialog buttons={[Buttons.Yes, Buttons.No]} {...props}></SimpleDialog>
}

export interface AlertDialogProps extends SimpleDialogProps {}
export const AlertDialog = function AlertDialog(props: AlertDialogProps) {
	return <SimpleDialog buttons={[Buttons.OK]} {...props}></SimpleDialog>
}
export interface TextDialogProps extends Omit<SimpleDialogProps, 'content'> {
	text?: string
	prompt?: string
	multiline?: boolean
	minRows?: number
	maxRows?: number
}
export interface TextDialogResult {
	result: ButtonType
	text: string
}
export const TextDialog = function TextDialog(props: TextDialogProps) {
	const { text, prompt, multiline, minRows, maxRows } = props
	const [value, setValue] = useState(text)

	const valueChanged = useCallback((e: any) => {
		setValue(e.target.value)
	}, [])
	const buttonClick = useCallback(
		(deferredPromise: DeferredPromise, buttonId: number) => {
			deferredPromise.resolve({ result: buttonId, text: value })
		},
		[value]
	)
	const keyDown = useCallback(
		(e: any) => {
			if (!multiline) return
			const k = e.key
			if (k == 'Enter') {
				if (e.ctrlKey) {
					e.ctrlKey = false // this prevents the default action of the enter key from being sent to the parent control.
				} else e.stopPropagation()
			} else if (['Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp'].includes(k)) {
				// this prevents any parent controls from taking over the keystrokes.
				// We can't preventDefault(), but we can stopPropagation so that the navigation keys are processed by this control.
				e.stopPropagation()
			}
		},
		[multiline]
	)
	const content = useMemo(() => {
		let flexProps = {}
		if (multiline) {
			flexProps = { f: 'c' }
		} else {
			flexProps = { f: 'r', alignItems: 'center' }
		}
		return (
			<Flex {...flexProps}>
				{prompt && <Text>{prompt}</Text>}

				<Flex fill>
					{/* This allows the text field to take up the rest of the space without crowding the label */}
					<TextField
						autoFocus
						multiline={multiline}
						minRows={minRows}
						maxRows={maxRows}
						value={value}
						onChange={valueChanged}
						onKeyDown={keyDown}
						fullWidth
						sx={{
							flexGrow: '1'
						}}
					/>
				</Flex>
			</Flex>
		)
	}, [keyDown, maxRows, minRows, multiline, prompt, value, valueChanged])

	return <SimpleDialog buttons={[Buttons.OK, Buttons.Cancel]} {...props} content={content} onButtonClick={buttonClick} />
}
export interface ComboBoxDialogProps extends SimpleDialogProps {
	provider: ComboBoxProvider
	value?: any
}
export interface ComboBoxDialogResult {
	result: ButtonType
	value?: any
}
export const ComboBoxDialog = function ComboBoxDialog(props: ComboBoxDialogProps) {
	const { provider, value } = props
	const [data, setData] = useState(value)

	const valueChanged = useCallback((value: any) => {
		setData(value)
	}, [])
	const buttonClick = useCallback(
		(deferredPromise: DeferredPromise, buttonId: number) => {
			deferredPromise.resolve({ result: buttonId, value: data })
		},
		[data]
	)

	return (
		<SimpleDialog
			buttons={[Buttons.OK, Buttons.Cancel]}
			{...props}
			content={<ComboBox provider={provider} value={value} onItemSelected={valueChanged} />}
			onButtonClick={buttonClick}
		/>
	)
}

export interface FinishDialogResult {
	result?: ButtonType
	data?: any
	preventClose?: boolean
}
export type OnFinishDialogEvent = (buttonId: ButtonType, data: any) => Promise<FinishDialogResult | undefined>
export interface ICustomDialogCallbackProps {
	// This property is listed as optional because it is supplied by the dialog infrastructure and is not expected to be provided by the caller.
	dataChanged?: (data: any) => void
	// The contained Dialog (that this is passed to) can call this function to set the onFinish event.
	// This is used to allow the dialog to prevent the dialog from closing, or to change the result or data of the dialog.
	setHooks?: (onFinish?: OnFinishDialogEvent) => void
}
export interface CustomDialogProps extends SimpleDialogProps {
	dialog: ReactElement<ICustomDialogCallbackProps>
}
export function CustomDialog(props: CustomDialogProps) {
	const { dialog, ...rest } = props

	// const [dialogData, setDialogData] = useState()
	const dialogData = useRef()
	const onFinish = useRef<OnFinishDialogEvent | undefined>()

	const buttonClick = useCallback(
		async (deferredPromise: DeferredPromise, buttonId: number) => {
			if (onFinish.current) {
				const res = await onFinish.current(buttonId, dialogData.current)
				if (res?.preventClose) return
				if (res?.result) buttonId = res.result
				if (res?.data) dialogData.current = res.data
			}

			deferredPromise.resolve({ result: buttonId, data: dialogData.current })
		},
		[onFinish]
	)
	const dataChanged = useCallback((data: any) => {
		dialogData.current = data
	}, [])
	const setHooks = useCallback((onFinishEvent?: OnFinishDialogEvent) => {
		onFinish.current = onFinishEvent
	}, [])
	const content = React.cloneElement(dialog, { dataChanged, setHooks })
	return <SimpleDialog buttons={[Buttons.OK, Buttons.Cancel]} content={content} {...rest} onButtonClick={buttonClick} />
}

type SetContentFunction = (content: string) => void
export interface BusyDialogProps extends SimpleDialogProps {
	hookContent?: (setter: SetContentFunction) => void
}
export const BusyDialog = function BusyDialog(props: BusyDialogProps) {
	const { content: origContent, hookContent, ...rest } = props
	const [content, setContent] = useState(props.content)
	useEffect(() => {
		if (hookContent) hookContent(setContent)
	}, [hookContent])

	return <SimpleDialog buttons={[]} {...rest} content={content}></SimpleDialog>
}

// interface IState {}
// export class BaseModalDialog<T extends BaseModalDialogProps, S = IState> extends React.Component<T, S> {
// 	deferredPromise: any
// 	constructor(props: T) {
// 		super(props)
// 		// this.state = {}
// 		this.deferredPromise = props.deferredPromise
// 	}
// 	resolve(res: number) {
// 		this.deferredPromise.resolve(res)
// 	}
// 	buttonClick(id: number) {
// 		this.resolve(id)
// 	}
// 	renderButtons(buttons?: ModalButtonList) {
// 		if (!buttons) return null
// 		const rendered = buttons.map((b, idx) => (
// 			<Button key={idx} onClick={() => this.buttonClick(b.id)}>
// 				{b.caption}
// 			</Button>
// 		))
// 		return <DialogActions>{rendered}</DialogActions>
// 	}
// }
// export interface ModalDialogProps extends BaseModalDialogProps {
// 	title?: React.ReactNode
// 	content?: React.ReactNode
// 	buttons?: ModalButtonList
// }
// export class ModalDialog<S extends IState = IState> extends BaseModalDialog<ModalDialogProps, S> {
// 	content: React.ReactNode | undefined
// 	title: React.ReactNode | undefined
// 	buttons: ModalButtonList | undefined

// 	constructor(props: ModalDialogProps) {
// 		super(props)
// 		// this.state = {}
// 		this.title = props.title
// 		this.content = props.content
// 		this.buttons = props.buttons
// 	}
// 	getTitle() {
// 		if (typeof this.title != 'string') return this.title
// 		return <Text sx={{ whiteSpace: 'pre' }}>{this.title}</Text>
// 	}
// 	getContent() {
// 		if (typeof this.content != 'string') return this.content
// 		return <Text sx={{ whiteSpace: 'pre' }}>{this.content}</Text>
// 	}
// 	render() {
// 		return (
// 			<Dialog open={true} title={this.title} content={this.getContent()}>
// 				{this.renderButtons(this.buttons)}
// 			</Dialog>
// 		)
// 	}
// }
// export class AlertDialog extends ModalDialog {
// 	constructor(props: AlertDialogProps) {
// 		super(props)
// 		this.buttons = [Buttons.OK]
// 	}
// }
// export class YesNoDialog extends ModalDialog {
// 	constructor(props: YesNoDialogProps) {
// 		super(props)
// 		this.buttons = [Buttons.Yes, Buttons.No]
// 	}
// }
// export interface CustomDialogProps<S> extends BaseModalDialogProps {
// 	dialog: BaseModalDialog<BaseModalDialogProps, S>
// }
// export class CustomDialog<S> extends BaseModalDialog<BaseModalDialogProps, S> {
// 	dialog: BaseModalDialog<BaseModalDialogProps, S>
// 	constructor(props: CustomDialogProps<S>) {
// 		super({ deferredPromise: props.deferredPromise })
// 		this.dialog = props.dialog
// 	}
// 	render() {
// 		return this.dialog
// 	}
// }
