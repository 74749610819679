import anylogger from '@app/anylogger'
import { ITreeViewItem, ITreeViewProvider } from './TreeViewProvider'

const log = anylogger('TreeViewProviderAdapter')

export class TreeViewProviderAdapter<T = any> {
	provider: ITreeViewProvider<any>
	rootItem: ITreeViewItem<T>
	constructor(provider: ITreeViewProvider) {
		this.provider = provider
		this.rootItem = { id: 'root', label: 'root', children: undefined, source: undefined as T }
	}
	setProvider(provider: ITreeViewProvider) {
		this.provider = provider
		this.rootItem.children = undefined
	}
	/**
	 * This ensures that all the children of expanded nodes are returned so that you can see
	 * @param expanded A list of all the expanded nodes
	 * @returns The list of items in the tree that are either expanded or already asynchronously retrieved.
	 */
	async getItems(expanded: string[]): Promise<ITreeViewItem<T>[]> {
		const self = this
		async function getItemsForNode(item?: ITreeViewItem<T>): Promise<ITreeViewItem<T>[]> {
			if (!item) item = self.rootItem

			const items = await self.populateChildren(item!)
			if (expanded.includes(item.id) || item.id === 'root') {
				for (let ii = 0; ii < items.length; ii++) {
					const child = items[ii]
					const children = await getItemsForNode(child)
					child.children = children
				}
			}
			return items
		}

		const items = await getItemsForNode()

		return items
	}
	async populateChildren(item: ITreeViewItem<T>): Promise<ITreeViewItem<T>[]> {
		if (!Array.isArray(item.children)) {
			item.children = await this.provider.getChildren(item.source)
		}

		return item.children
	}
	getItemById(items: ITreeViewItem<T>[], itemId: string): ITreeViewItem<T> | undefined {
		for (const item of items) {
			if (item.id === itemId) return item
			if (item.children) {
				const found = this.getItemById(item.children, itemId)
				if (found) return found
			}
		}
		return
	}
}
