import { Point } from '@app/utils'
import anylogger from '@app/anylogger'

const log = anylogger('mouseTouchUtils')

export const PrimaryButton = 0
export const SecondaryButton = 2
export const MiddleButton = 1
export const MouseBackButton = 3
export const MouseForwardButton = 4

export type ButtonType =
	| typeof PrimaryButton
	| typeof SecondaryButton
	| typeof MiddleButton
	| typeof MouseBackButton
	| typeof MouseForwardButton

export type HTMLRef = HTMLElement | Document | Window | null
export interface MouseTouchEvent {
	pos: Point
	button?: ButtonType
	data?: any
	isTouch: boolean
	mouseEvent?: MouseEvent
	touchEvent?: TouchEvent
}
export function getEvent(e: MouseTouchEvent): Event {
	return e.isTouch ? e.touchEvent! : e.mouseEvent!
}
export type MouseTouchEventHandler = (e: MouseTouchEvent) => boolean | void

export function createPoint(e: any) {
	return new Point(e.clientX, e.clientY)
}
export function createMouseEvent(e: MouseEvent, data: any): MouseTouchEvent {
	return { pos: createPoint(e), button: e.button as ButtonType, data: data, isTouch: false, mouseEvent: e }
}
export function createTouchEvent(e: TouchEvent, data: any): MouseTouchEvent {
	const touches = e.touches?.length ? e.touches : e.changedTouches
	return { pos: createPoint(touches[0]), data: data, isTouch: true, touchEvent: e }
}
