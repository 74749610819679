import React, { ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { Flex, FlexProps } from './Flex'
import { Button, Tab, Tabs, TabsProps, ToggleButtonGroup, ToggleButton, useTheme, TabProps } from '@mui/material'
import { useLocalStorageOrState } from '@app/hooks'
import { ThemeContext } from '@emotion/react'
import { TypesafeBoolean } from '@app/utils'
import { TabContext, TabList } from '@mui/lab'

const log = anylogger('TabFlex')

interface TabPage {
	label: string
	content: ReactNode
	id?: string
	tabVisible?: boolean
}
export type TabPageList = TabPage[]

export type ChangeActiveTab = (tab: string) => void

// export class TabPageList extends Array<TabPage> {
// 	constructor() {
// 		super()
// 	}
// 	addTab(label: string, content: ReactNode) {
// 		this.push({ label, content })
// 	}
// }
interface TabFlexProps extends FlexProps {
	/**
	 * The list of TabPageInit objects to render */
	tabs: TabPage[]

	/**
	 * Default: undefined. Specifies the tab that is to be active by default.  If not specified, it defaults to the first tab */
	initialTab?: string

	/**
	 * Default: undefined. Specifies the local storage key to store the active tab in so that it is
	 * retained across browser refreshes. If not specified, the active tab will be reset to the initialTab
	 * on every browser refresh. */
	localStorageKey?: string
	onTabChange?: (tab: string) => void
	/**
	 * Default: true. Set this to false to hide the tabs at the top, but still be able to see the tab content*/
	tabsVisible?: boolean

	ariaLabel?: string
	/**
	 * If present, this component will be rendered to the right of the tabs, with space between.
	 */
	tabContainerAdornment?: ReactNode

	/**
	 * This callback will be called as soon as this component is rendered. It will pass a function
	 * that can be called to change the active tab. */
	setChangeActiveTab?: (changeActiveTab: ChangeActiveTab) => void
}
export const TabFlex = React.forwardRef(function TabFlex(props: TabFlexProps, ref: any) {
	let {
		tabs,
		initialTab = tabs[0]?.id || tabs[0]?.label,
		localStorageKey,
		onTabChange,
		tabsVisible = true,
		setChangeActiveTab,
		children,
		ariaLabel = 'Tab Pages',
		tabContainerAdornment,
		...rest
	} = props
	// if (!tabs || !tabs.length) throw new Error(`You must specify at least one item in the tabs prop`)

	const [activeTab, setActiveTab] = useLocalStorageOrState(localStorageKey, initialTab)

	const changeTab = useCallback(
		(tab: string) => {
			setActiveTab(tab)
			if (onTabChange) onTabChange(tab)
		},
		[onTabChange, setActiveTab]
	)
	useEffect(() => {
		if (!setChangeActiveTab) return
		setChangeActiveTab(changeTab)
	}, [changeTab, setActiveTab, setChangeActiveTab])

	const getId = useCallback((tab: TabPage) => {
		return tab.id || tab.label
	}, [])
	const renderButtons = useCallback(() => {
		return tabs
			.map((tab) => {
				if (typeof tab.tabVisible != 'undefined' && !tab.tabVisible) return null
				const id = getId(tab)
				return <Tab key={id} label={tab.label} value={id} />
			})
			.filter(TypesafeBoolean)
	}, [getId, tabs])

	function tabChange(e: EventInit, value: string) {
		changeTab(value)
	}

	const renderActiveTab = useCallback(() => {
		if (!activeTab) return null
		const tab = tabs.find((tab) => getId(tab) == activeTab)
		if (!tab) return null

		return <Flex fill>{tab.content}</Flex>
	}, [activeTab, tabs, getId])
	if (!tabs?.length) return null

	return (
		<TabContext value={activeTab}>
			<Flex f="c" fill {...rest}>
				<Flex f="rw" justifyContent="space-between">
					<TabList onChange={tabChange} aria-label={ariaLabel}>
						{renderButtons()}
					</TabList>
					<Flex>{tabContainerAdornment}</Flex>
				</Flex>
				<Flex fill position="relative">
					{renderActiveTab()}
				</Flex>
			</Flex>
		</TabContext>
	)
})
