import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import anylogger from '@app/anylogger'
import { useLocalStorage } from './useLocalStorage'
import { equals } from '@app/utils'

const log = anylogger('useLocalStorageState')

/**
 * This is meant to be used by components that can accept an optional storage key
 * to persiste state pertaining to their functionality.  If the client passes a key,
 * then local storage is used, but if no key is used, useState is used which only persists
 * until the browser is refreshed
 * @param key The key to use for local storage
 * @param initialValue The initial Value
 * @returns [value, setValue], just like useState, but could be from useLocalStorage if a key is passed in
 */
export function useLocalStorageOrState<T = any>(key: string | undefined, initialValue: T): [T, Dispatch<SetStateAction<T>>] {
	const storageVals = useLocalStorage<T>(key, initialValue)
	const stateVals = useState<T>(initialValue)
	return useMemo(() => {
		return key ? (storageVals as [T, Dispatch<SetStateAction<T>>]) : stateVals
	}, [key, stateVals, storageVals])
}
