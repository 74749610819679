import React, { useState } from 'react'
import anylogger from '@app/anylogger'
import { ErrorView, Flex, FlexC, Text } from '@app/muiplus'
import { Box, Button } from '@mui/material'
import SendErrorReport from './SendErrorReport'

const log = anylogger('ErrorBoundary')

interface IProps {
	children?: React.ReactNode
}
enum ResetState {
	None,
	Clearing,
	Cleared
}
interface IState {
	hasError: boolean
	error: any
	resetState: ResetState
}
export class ErrorBoundary extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props)
		this.state = { hasError: false, error: '', resetState: ResetState.None }
	}
	private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
		log('promiseRejectionHandler', event.reason)

		this.setState({
			hasError: true,
			error: event.reason,
			resetState: ResetState.None
		})
	}
	componentDidMount() {
		// Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
		window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
	}

	componentWillUnmount() {
		window.removeEventListener('unhandledrejection', this.promiseRejectionHandler)
	}
	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		console.log('getDerivedStateFromError', error)

		return { hasError: true, error: error, resetState: ResetState.None }
	}

	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
		log('error', error, errorInfo)
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<FlexC mx="1em">
					<Flex fill justifyContent="center">
						<div>
							<Box
								component="h2"
								sx={{
									textAlign: 'center'
								}}
							>
								Something went horribly, horribly wrong.
							</Box>
							<Box component="h3" sx={{ textAlign: 'center' }}>
								At least there wasn't an earth-shattering kaboom.
							</Box>
							<Box sx={{ textAlign: 'center' }}>
								<SendErrorReport />
							</Box>

							<Box component="h3" sx={{ textAlign: 'center' }}>
								The error was only:
							</Box>
						</div>
					</Flex>
					<ErrorView error={this.state.error} />
				</FlexC>
			)
		}

		return <>{this.props.children}</>
	}
}
