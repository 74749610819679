import anylogger from '@app/anylogger'
import { ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useCurrentRef } from '@app/hooks'
import { clone, Guid, JsonValueProvider } from '@app/utils'
import { FieldDefInit, FieldDefinition, FieldEditorProps } from './EditorService'
import { Flex, FlexC, FlexProps } from './Flex'
import { ICustomDialogCallbackProps } from './ModalDialog'
import { findFirstFocusableElement } from './findFirstFocusableElement'
import { EditorConfig } from './EditorConfig'
import React from 'react'
import { Grid, Typography } from '@mui/material'
import {
	CheckboxEditor,
	DateEditor,
	ItemSelectorEditor,
	MultilineEditor,
	NumberEditor,
	StringEditor,
	StringListEditor
} from './DefaultFieldEditors'

const log = anylogger('EditDialog')

interface EditorService<T extends object = any> {
	addEditor: (key: string, editor: any) => void
	getEditor: (field: FieldDefinition<T>, id: string, data: T, dataChanged: (data: T) => void) => any
	data: T
	dataChanged: (data: T) => void
	addDefs: (newDefs: FieldDefinition<T>[]) => void
	addDef: (newDef: FieldDefinition<T> | undefined) => void
}
export const EditorServiceContext = React.createContext<EditorService>(undefined!)

export function useEditor<T extends object = any>(): EditorService<T> {
	const context: EditorService<T> = useContext(EditorServiceContext)
	return context
}
interface EditDialogProps<T extends object = any> extends ICustomDialogCallbackProps, FlexProps {
	// inherited
	// dataChanged: (data: T) => void
	data: T
	fields?: FieldDefInit<T>[]
	shouldFocusFirst?: boolean
	children?: ReactNode
}
export function EditDialog<T extends object = any>(props: EditDialogProps<T>) {
	const { data: origData, dataChanged, ...rest } = props
	if (!dataChanged) throw new Error('dataChanged is required')
	// we have to

	// const [refreshStatus, setRefreshStatus] = useState(0)
	// const [hasErrors, setHasErrors] = useState(false)
	// const inValidate = useRef(false)

	const defs = useRef<FieldDefinition<any>[]>([])
	const editors = useRef<Record<string, React.FunctionComponent<FieldEditorProps<any>>>>({})
	defs.current = []

	// make a copy of the original data so that we don't mutate the original object
	const data = useRef<any>()
	if (!data.current) {
		data.current = clone(origData)
		// we have to call dataChanged up front, or the hosting modal dialog will not have a copy of the data to return if nothing is changed in the dialog
		dataChanged(data.current)
	}

	const addDefs = (newDefs: FieldDefinition<any>[]) => {
		defs.current.push(...newDefs)
	}
	const addDef = (newDef: FieldDefinition<any> | undefined) => {
		if (!newDef) return
		defs.current.push(newDef)
	}
	const addEditor = useCallback((key: string, editor: any) => {
		editors.current[key] = editor
	}, [])
	useEffect(() => {
		addEditor('string', StringEditor)
		addEditor('multiline', MultilineEditor)
		addEditor('number', NumberEditor)
		addEditor('bigint', NumberEditor)
		addEditor('date', DateEditor)
		addEditor('boolean', CheckboxEditor)
		addEditor('itemSelector', ItemSelectorEditor)
		addEditor('stringListEditor', StringListEditor)
	}, [addEditor])

	const getEditor = useCallback((field: FieldDefinition<T>, id: string, data: T, dataChanged: (data: T) => void): any => {
		const editor = editors.current[field.editor.type]
		if (!editor) return <Typography>{`Unknown editor: ${field.editor.type}`}</Typography>
		return React.createElement(editor, { key: id, fieldDef: field, data, dataChanged })
	}, [])
	const localDataChanged = useCallback(
		(newData: T) => {
			// we are NOT using the new data that is passed in to this function (the newData prop should probably be removed)
			// This is because subeditor forms (especially nested ones), may not have access to the base object.
			// This is OK because all Fields mudate the data object directly, and this EditDialog creates its own private copy so that we don't mutate the original.
			// So, as the user edits data, data.current is continually mutated and is up to date.
			dataChanged(data.current)
		},
		[dataChanged]
	)
	const res = {
		addEditor,
		getEditor,
		data: data.current,
		dataChanged: localDataChanged,
		addDefs,
		addDef
	}

	return (
		<EditorServiceContext.Provider value={res}>
			<InternalEditDialog {...rest} />
		</EditorServiceContext.Provider>
	)
}
interface InternalEditDialogProps<T extends object = any> {
	shouldFocusFirst?: boolean
	fields?: FieldDefInit<T>[]
	children?: ReactNode
}
export function InternalEditDialog<T extends object = any>(props: InternalEditDialogProps<T>) {
	const { shouldFocusFirst, fields, children, ...rest } = props

	const [ref, setRef] = useCurrentRef<HTMLElement>()
	const [focusedFirst, setFocusedFirst] = useState(false)

	useEffect(() => {
		if (!shouldFocusFirst || focusedFirst || !ref) return
		setFocusedFirst(true)
		const child = findFirstFocusableElement(ref)
		if (child) child.focus()
	}, [focusedFirst, ref, shouldFocusFirst])

	const [defs, setDefs] = useState<FieldDefinition[]>([])

	const ctx = useEditor()
	ctx.addDefs(defs)

	useEffect(() => {
		if (!fields) return
		setDefs(FieldDefinition.initList(fields))
	}, [fields])
	// const dataChanged = useCallback(
	// 	(data: any) => {
	// 		if (ctx.dataChanged) ctx.dataChanged(data)
	// 	},
	// 	[ctx]
	// )

	const getFields = useCallback(() => {
		if (!ctx || !fields) return null
		return fields.map((fld, idx) => {
			return <Field key={idx} fieldDef={fld} />
			// return svc.getEditor(idx, fld, data, dataChanged, ref)
		})
	}, [ctx, fields])

	return (
		<FlexC ref={setRef} padding="0.25em" {...rest}>
			{getFields()}
			{children}
		</FlexC>
	)
}
interface EditorSubDialogProps<T extends object = any> {
	data: T
	fields?: FieldDefInit<T>[]
	dataChanged?: (data: T) => void
	children?: ReactNode
}
export const EditorSubDialog = React.forwardRef(function EditorSubForm<T extends object = any>(props: EditorSubDialogProps, ref: any) {
	const { data, fields, children, dataChanged } = props

	const ctx = useEditor()

	const internalDataChanged = useCallback(
		(newData: T) => {
			if (dataChanged) dataChanged(newData)
			return ctx.dataChanged(newData)
		},
		[ctx, dataChanged]
	)
	const getFields = useCallback(() => {
		if (!ctx || !fields) return null
		return fields.map((fld, idx) => {
			return <Field key={idx} fieldDef={fld} />
		})
	}, [ctx, fields])

	const newCtx = { ...ctx, data, dataChanged: internalDataChanged }
	return (
		<EditorServiceContext.Provider value={newCtx}>
			{getFields()}
			{children}
		</EditorServiceContext.Provider>
	)
})

interface LabeledFIeldGridProps {
	fieldDefs: FieldDefInit<any>[]
}
export const LabeledFieldGrid = React.forwardRef(function LabeledFieldGrid(props: LabeledFIeldGridProps, ref: any) {
	const { fieldDefs } = props

	const [defs, setDefs] = useState<FieldDefinition[]>([])

	const ctx = useEditor()
	ctx.addDefs(defs)

	useEffect(() => {
		setDefs(FieldDefinition.initList(fieldDefs))
	}, [fieldDefs])

	const fieldContent = useMemo(() => {
		if (!defs.length) return null

		const fields = fieldDefs.reduce((res, def, idx) => {
			log('def', defs)

			res.push(<label key={idx.toString() + 'lbl'}>{defs[idx].name}</label>)
			res.push(<Field fieldDef={def} key={idx} />)
			return res
		}, [] as any[])
		return fields
	}, [defs, fieldDefs])

	return (
		<Grid mx="auto" gridTemplateColumns="auto 1fr" gap={0.5} alignItems="center" justifyContent="space-evenly">
			{fieldContent}
		</Grid>
	)
})

interface FlexFieldsProps extends FlexProps {
	fieldDefs: FieldDefInit<any>[]
}

export const FlexFields = React.forwardRef(function FlexFields(props: Omit<FlexFieldsProps, 'ref'>, ref: any) {
	const { fieldDefs } = props

	const [defs, setDefs] = useState<FieldDefinition[]>([])

	const ctx = useEditor()
	ctx.addDefs(defs)

	useEffect(() => {
		setDefs(FieldDefinition.initList(fieldDefs))
	}, [fieldDefs])

	const getFields = useCallback(() => {
		if (!ctx) return
		return fieldDefs.map((fld, idx) => {
			return <Field key={idx} fieldDef={fld} />
		})
	}, [ctx, fieldDefs])

	return <FlexC>{getFields()}</FlexC>
})

interface FieldProps<T extends object = any> {
	fieldDef: FieldDefInit<T>
	parentRef?: Element
}
export function Field(props: FieldProps) {
	const { fieldDef, parentRef } = props
	const [def, setDef] = useState<FieldDefinition>()
	const cr = useRef<string>(Guid())

	const ctx = useEditor()
	useEffect(() => {
		setDef(FieldDefinition.init(fieldDef))
	}, [fieldDef])

	const svc = useEditor()
	if (!def) return null
	return svc.getEditor(def, cr.current, ctx.data, ctx.dataChanged)
}
