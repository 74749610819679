import { alpha, IconButton, ListItemIcon, styled, TextField } from '@mui/material'
import { FieldEditorProps } from './EditorService'
import { Checkbox } from './CheckBox'
import { ItemSelector } from './ItemSelector'
import { useCheckboxFieldEditor, useItemSelectorFieldEditor, useStringListEditor, useTextFieldEditor } from './hooks/useTextFieldEditor'
import anylogger from '@app/anylogger'
import { useCallback, useState } from 'react'
import { useCurrentRef, useEvent } from '@app/hooks'
import { sendKey } from '@app/utils'
import { FlexR } from './Flex'
import EditIcon from '@mui/icons-material/Edit'
import { useModal } from './ModalOverlay'
import { ICustomDialogCallbackProps, OK } from './ModalDialog'

const log = anylogger('DefaultFieldEditors')

export function StringEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const editProps = useTextFieldEditor(props)
	const keyDown = useCallback((e: any) => {
		const k = e.key
		if (['Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp'].includes(k)) {
			// this prevents any parent controls from taking over the keystrokes.
			// We can't preventDefault(), but we can stopPropagation so that the navigation keys are processed by this control.
			e.stopPropagation()
		}
	}, [])
	// in the absence of any specific context props, they will all be sent to the underlying control
	return <TextField variant="outlined" {...editProps} onKeyDown={keyDown} {...props.fieldDef.editor.context} />
}
export function MultilineEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const editProps = useTextFieldEditor(props)
	let { minRows, maxRows } = props.fieldDef.editor.context
	const [ref, setRef] = useCurrentRef<HTMLElement>()

	const keyDown = useCallback((e: any) => {
		const k = e.key
		if (k == 'Enter') {
			if (e.ctrlKey) {
				e.ctrlKey = false // this changes a Ctrl-Enter to enter so that it can be processed by the parent control.
			} else e.stopPropagation()
		} else if (['Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp'].includes(k)) {
			// this prevents any parent controls from taking over the keystrokes.
			// We can't preventDefault(), but we can stopPropagation so that the navigation keys are processed by this control.
			e.stopPropagation()
		}
	}, [])
	return (
		<TextField
			inputRef={setRef}
			multiline
			variant="outlined"
			{...editProps}
			minRows={minRows}
			maxRows={maxRows}
			onKeyDown={keyDown}
			{...props.fieldDef.editor.context}
		/>
	)
}
export function CheckboxEditor<T extends object = any>(props: FieldEditorProps<T>) {
	let editProps = useCheckboxFieldEditor(props)

	delete (editProps as any).helperText
	delete (editProps as any).error
	return <Checkbox {...editProps} {...props.fieldDef.editor.context} />
}
export function NumberEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const editProps = useTextFieldEditor(props)
	const keyDown = useCallback((e: any) => {
		const k = e.key
		if (['Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp'].includes(k)) {
			// this prevents any parent controls from taking over the keystrokes.
			// We can't preventDefault(), but we can stopPropagation so that the navigation keys are processed by this control.
			e.stopPropagation()
		}
	}, [])
	return <TextField type="number" variant="outlined" {...editProps} onKeyDown={keyDown} {...props.fieldDef.editor.context} />
}

export function DateEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const editProps = useTextFieldEditor(props)
	const keyDown = useCallback((e: any) => {
		const k = e.key
		if (['Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp'].includes(k)) {
			// this prevents any parent controls from taking over the keystrokes.
			// We can't preventDefault(), but we can stopPropagation so that the navigation keys are processed by this control.
			e.stopPropagation()
		}
	}, [])
	return <TextField type="date" variant="outlined" {...editProps} onKeyDown={keyDown} {...props.fieldDef.editor.context} />
}

export function ItemSelectorEditor<T extends object = any>(props: FieldEditorProps<T>) {
	let editProps = useItemSelectorFieldEditor(props)
	const { allItems, allowNew, multiple = true, ...rest } = props.fieldDef.editor.context || {}
	if (multiple && !Array.isArray(editProps.value)) editProps.value = []
	return <ItemSelector multiple={multiple} freeSolo={allowNew} options={allItems} {...editProps} {...rest} />
}
export function StringListEditor<T extends object = any>(props: FieldEditorProps<T>) {
	let editProps = useStringListEditor(props)
	const { value, displayValue, setValue, ...rest } = editProps

	const sx = props.fieldDef.editor.context || {}

	const { customDialog, textDialog } = useModal()
	const editList = useCallback(async () => {
		const res = await textDialog({
			title: 'Edit the list of strings',
			text: value,
			multiline: true,
			minRows: 5,
			maxRows: 30,
			sx: { width: '800px' }
		})
		if (res.result == OK) {
			if (res.text) {
				setValue(res.text)
			}
		}
		// const res = await customDialog({
		// 	title: 'Edit the list of strings',
		// 	dialog: <StringListDialog value={value} />
		// })
		// if (res.result == OK) {
		// 	if (res.data) {
		// 		setValue(res.data)
		// 	}
		// }
	}, [setValue, textDialog, value])
	return (
		<FlexR>
			<TextField fullWidth value={displayValue} {...rest} {...sx} />

			<CustomIconButton onClick={editList}>
				<EditIcon></EditIcon>
			</CustomIconButton>
		</FlexR>
	)
}
interface StringListEditorProps extends ICustomDialogCallbackProps {
	value: string
}
function StringListDialog(props: StringListEditorProps, ref: any) {
	const { value, dataChanged } = props
	const [editValue, setEditValue] = useState(value)

	const keyDown = useCallback((e: any) => {
		const k = e.key
		if (k == 'Enter') {
			if (e.ctrlKey) {
				e.ctrlKey = false // this prevents the default action of the enter key from being sent to the parent control.
			} else e.stopPropagation()
		} else if (['Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp'].includes(k)) {
			// this prevents any parent controls from taking over the keystrokes.
			// We can't preventDefault(), but we can stopPropagation so that the navigation keys are processed by this control.
			e.stopPropagation()
		}
	}, [])
	const onChange = useCallback(
		(e: any) => {
			const val = e.target.value
			setEditValue(val)
			if (dataChanged) dataChanged(val)
		},
		[dataChanged]
	)
	return (
		<TextField
			multiline
			fullWidth
			variant="outlined"
			minRows={5}
			maxRows={30}
			onKeyDown={keyDown}
			value={editValue}
			onChange={onChange}
			sx={{
				width: '500px',
				maxWidth: '90%'
			}}
		/>
	)
}
const CustomIconButton = styled(IconButton)(({ theme }) => ({
	// padding: '0px',
	height: '1.5em',
	width: '1.5em',
	// margin: '5px',
	'&:hover': {
		// we are using selectedOpacity to distinguish from the item which the button is on, which already has hover opacity
		backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
	}
}))
